import React, { useContext } from "react";

// importing material UI components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from "@mui/icons-material/Info";
import { Help, LogoutOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import "./Header.css";
import en from "./i18n/en";
import es from "./i18n/es";
import i18next from "i18next";
import { LanguageSwitcher } from "./commons/LanguageSwitcher";
import AppContext from "./AppContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GenericTextModalConfirmationWithButton from "./components/modals/GenericTextModalConfirmationWithButton/GenericTextModalConfirmationWithButton";
import { Paper, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Description from "./commons/Description/Description";
import MapIcon from "@mui/icons-material/Map";
import Notiflix from "notiflix";
i18next.addResourceBundle("en", "HEADER_TRANSLATION", en);
i18next.addResourceBundle("es", "HEADER_TRANSLATION", es);

export default function Header({
  highlightedElement,
  showHelpButton = true,
  style = { backgroundColor: "rgb(var(--color-primary))" },
  className = "",
}) {
  const { t } = useTranslation("HEADER_TRANSLATION");
  const navigate = useNavigate();
  const { userHeader, setUserHeader, token, setToken } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const goToMain = () => {
    navigate("/main");
  };
  const goToMap = () => {
    navigate("/example/map");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
  // const logout = () => {
  //   const isAuthenticated = localStorage.removeItem(LOCAL_STORAGE_KEY);
  //   window.location.href = "/login";
  // };
  const getButtonClassName = (buttonName) => {
    return highlightedElement === buttonName
      ? "button-effect button-oval button-selected"
      : "button-effect button-oval";
  };
  function replaceWithLink(text, target, url) {
    const parts = text.split(target);

    return (
      <span>
        {parts.reduce((prev, curr, i) => {
          if (!i) {
            return [curr];
          } else {
            return prev.concat(
              <Link to={url} target="_blank" rel="noopener noreferrer">
                {target}
              </Link>,
              curr
            );
          }
        }, [])}
      </span>
    );
  }
  const logout = () => {
    Notiflix.Notify.info("Sesión cerrada");
    setToken("");
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    navigate("/login");
  };
  return (
    <>
      <AppBar
        position="inherit"
        className={"header " + className}
        style={{ ...style }}
      >
        <Toolbar style={{ display: "flex", flexDiraction: "row" }}>
          {/* <img
          loading="lazy"
          alt="logo"
          src={window.location.origin + "/images/logo-footer.png"}
        /> */}
          {/* <label className="header_title">{t("header_title")}</label> */}
          <a
            // href="https://www.agforest.ai"
            className="header_logo_v2"
            // target="_blank"
            onClick={(e) => {
              goToMain();
              e.stopPropagation();
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/BLANCO.png"}
              className="header_logo_img"
            />
          </a>

          {/* <div className="button-box">
          <Typography onClick={()=>{navigate("/users")}} style={{textTransform:"None"}} className={getButtonClassName("Users")}>{t("users")}</Typography>
        </div> */}
          <div
            style={{
              display: "flex",
              // backgroundColor: "var(--color-third)",

              justifyContent: "flex-end",
              width: "100%",
              // borderBottom: "2px solid rgb(var(--color-primary))",
            }}
          >
            {showHelpButton && (
              <>
                <Tooltip title="Menu">
                  <IconButton
                    onClick={handleMenuClick}
                    aria-label="Información"
                  >
                    {anchorEl ? (
                      <MenuOpenIcon
                        style={{
                          color: "rgb(var(--color-primary))",
                          backgroundColor: "rgb(var(--color-secondary))",
                          borderRadius: "50%",
                          padding: "0.3rem",
                        }}
                      />
                    ) : (
                      <MenuIcon
                        style={{
                          color: "rgb(var(--color-secondary))",
                          padding: "0.3rem",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                  <a
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "black",
                      }}
                      href="https://agforest.ai/"
                      target="_blank"
                    >
                      <AnnouncementIcon
                        style={{ marginRight: "1.2rem", color: "black" }}
                      />
                      <label>Información</label>
                    </a>
                  </MenuItem>

                  <MenuItem>
                    <GenericTextModalConfirmationWithButton
                      buttonAction={handleClose}
                      InitialAction={handleClose}
                      ModalButton={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Help style={{ marginRight: "1.2rem" }} />
                          Ley 07/2022
                        </div>
                      }
                      Title={t("txt_description_title")}
                      BodyComponent={
                        <Paper style={{ padding: "0.5rem", width: "100%" }}>
                          <label
                            style={{
                              textAlign: "justify",
                              textJustify: "inter-word",
                            }}
                          >
                            {replaceWithLink(
                              t("txt_description"),
                              "07/2022",
                              "https://www.boe.es/buscar/act.php?id=BOE-A-2022-5809"
                            )}
                          </label>
                        </Paper>
                      }
                      ConfirmText="Ok"
                      CancelText={false}
                    />
                  </MenuItem>

                  <MenuItem
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <a
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "black",
                      }}
                      href="https://agforest.ai/contacto"
                      target="_blank"
                    >
                      <AnnouncementIcon
                        style={{ marginRight: "1.2rem", color: "black" }}
                      />
                      <label>¿tienes alguna duda?</label>
                    </a>
                  </MenuItem>
                  {/* <MenuItem
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <a
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "black",
                      }}
                      // href="/example/map"
                      onClick={(e) => {
                        goToMap();

                        e.stopPropagation();
                      }}
                    >
                      <MapIcon
                        style={{ marginRight: "1.2rem", color: "black" }}
                      />
                      <label>Mapa de ejemplo</label>
                    </a>
                  </MenuItem> */}
                </Menu>
              </>
            )}
            <Tooltip title="Logout">
              <IconButton aria-label="Logout" onClick={logout}>
                <LogoutOutlined
                  style={{
                    color: "rgb(var(--color-secondary))",
                    backgroundColor: "rgb(var(--color-primary))",
                    borderRadius: "50%",
                    padding: "0.3rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
