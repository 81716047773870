import { Overlay } from "ol";
import Icon from 'ol/style/Icon.js';
import Feature from 'ol/Feature.js';
import BingMaps from 'ol/source/BingMaps.js';

import Map from "ol/Map";
import View from "ol/View";
import { Control, ScaleLine, defaults } from "ol/control";
import { createEmpty, extend, getCenter } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { get as getProjection } from "ol/proj";
import { register } from "ol/proj/proj4";
import { Cluster, Vector as VectorSource } from "ol/source";
import { Fill, Stroke,Text,  Style } from "ol/style";
import proj4 from "proj4";
import Vector from "ol/layer/Vector"
import XYZ from "ol/source/XYZ";
import CircleStyle from "ol/style/Circle";
import OSM from 'ol/source/OSM';
import { Point } from 'ol/geom';
import {isMobile} from 'react-device-detect';

var highlightedFeature = null;
const DEFAULT_STROKE_COLOR = "#000000";
const DEFAULT_STROKE_WIDTH = 2;
const DEFAULT_FILL_COLOR = '#3399CC';
const DEFAULT_FILL_OPACITY = 0.8;
const BREAKPOINT_ZOOM = isMobile ? 16.5 : 15.2; // mayor valor es más zoom
const STROKE_MULTIPLY = 6;
var geolocation_first_time = 0;
const startTime = performance.now();
export function calculeCenterOfFeatures(combinedExtent, polygonList, pointList) {
    //console.log("polygonList", polygonList);
    //console.log("pointList", pointList);

    if(polygonList == null || polygonList == []){
        polygonList = []

    }
    if(pointList == null){
        pointList = []
    }
    let geometryList = [...polygonList, ...pointList];
    // for (let i = 0; i < pointList.length; i++) {
    //     polygonList.push(pointList[i]); // Agrega cada elemento de pointList a geometryList
    //   }
    combinedExtent =  geometryList.reduce((extent, feature) => {
      if (feature == null) {
        return extent;
      }
      const geometry = feature.getGeometry();
      // Comprobar si la geometría existe y tiene el método getExtent
      if (geometry !== null) {
        try {
          return extend(extent, geometry.getExtent());
        } catch (e) {
          return extent;
        }
      }
  
      // Si la geometría es inválida, simplemente retorna el extent actual
      return extent;
    }, createEmpty());
    let combinedCenter = getCenter(combinedExtent);
    return { combinedExtent, combinedCenter };
  }
function getBgColorFromStatus(status){
  let values = {
    "OPEN":  "255, 50, 50",
    "PENDING" : "116, 86, 171",
    "CLOSED" : "185, 255, 183",
    // "OPEN":  "241, 154, 62",
    // "PENDING" : "72, 86, 101",
    // "CLOSED" : "185, 255, 183",
  }
  if(status in values){
    return values[status]
  }else{
    return false;
  }
}
function getTextColorFromStatus(status){
  let values = {
    "OPEN":  "0, 0, 0",
    "PENDING" : "0, 0, 0",
    "CLOSED" : "0, 0, 0",
    // "OPEN":  "241, 154, 62",
    // "PENDING" : "72, 86, 101",
    // "CLOSED" : "185, 255, 183",
  }
  if(status in values){
    return values[status]
  }else{
    return false;
  }
}

function getStrokeColorFromStatus(status){
  let values = {
    "OPEN":  "255, 0, 0",
    "PENDING" : "216, 206, 171",
    "CLOSED" : "185, 255, 183",
  }
  if(status in values){
    return values[status]
  }else{
    return false;
  }
}

export function getBgColorFromFeatureGeneric(allPolygons, index, getBgColorFromFeature) {
    let bgColor = false;
    let feature = allPolygons[index].values_
    bgColor = getBgColorFromFeature(feature)
    // if ("status" in element) {
    //   let status = element.status;
    //   bgColor = getBgColorFromStatus(status);
    // }
    return bgColor;
  }
  export function getSourceBaseMapFromName(selectedValue){
    let source = "";
    if (selectedValue === 'osm') {
      // Cambiar al mapa base OSM
      source =  new OSM();
    } else if (selectedValue === 'voyayer') {
      // Cambiar a tu mapa base personalizado (ajusta según tus necesidades)
      source =  new XYZ({
          url: "http://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
        });
    }else if (selectedValue === 'light_all') {
      // Cambiar a tu mapa base personalizado (ajusta según tus necesidades)
      source =  new XYZ({
          url: "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        });

    }else if(selectedValue === "arcgis"){
        source = new XYZ({
          url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
        })      
    }else if(selectedValue === "PNOAmaximaActualidad"){
      source = new XYZ({
        url: "https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg",
      })

      
      //"https://www.ign.es/wms-inspire/pnoa-ma?request=GetCapabilities&service=WMS"
    }else if(selectedValue == "bing_AerialWithLabelsOnDemand"){
      //https://openlayers.org/en/latest/examples/bing-maps.html
      source= new BingMaps({
        key: 'Your Bing Maps Key from https://www.bingmapsportal.com/ here',
        imagerySet: "AerialWithLabelsOnDemand",
        //crossOrigin:"https://www.asbestos.ai",
        projection:"EPSG:4326",
        key:"ArEHaClBNxd5sNIvqtthamdnShR1GQkYSOxPMgNiQmn2d4fCXxWQ0Hdb2l8mUzI3",//"Ag97niDOM4O5tEEHeg9Myjw2L5UYWD1kS1ptgFdn7wJ2C5YFL5S7W5dosgx51LFU"
        placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
      })
    }else if(selectedValue == "bing_OrdnanceSurvey"){
      //https://openlayers.org/en/latest/examples/bing-maps.html
      source= new BingMaps({
        key: 'Your Bing Maps Key from https://www.bingmapsportal.com/ here',
        imagerySet: "OrdnanceSurvey",
        //crossOrigin:"https://www.asbestos.ai",
        projection:"EPSG:4326",
        key:"ArEHaClBNxd5sNIvqtthamdnShR1GQkYSOxPMgNiQmn2d4fCXxWQ0Hdb2l8mUzI3",//"Ag97niDOM4O5tEEHeg9Myjw2L5UYWD1kS1ptgFdn7wJ2C5YFL5S7W5dosgx51LFU"
        placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
      })
    }
    return source;
  }
  export function initProjections() {
    const epsg25830 = '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
    const epsg25829 = '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
    const epsg25831 = '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
    const epsg4326 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const epsg4083 =  "+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";

    proj4.defs('EPSG:25830', epsg25830);
    proj4.defs('EPSG:25829', epsg25829);
    proj4.defs('EPSG:25831', epsg25831);
    proj4.defs('EPSG:4326', epsg4326);
    proj4.defs('EPSG:4083', epsg4083);
  
    register(proj4);
    getProjection('EPSG:25830').setExtent([-1300000.000000, 3500000.000000, 1200000.000000, 5500000.000000]);
  }
  
  
  function getFeatureType(feature){
    let type = "Polygon";
   // console.log("Feature", feature,  (feature && "values_" in feature && "features" in feature["values_"] ? feature["values_"]["features"]:"No hay features"));
    if(feature && "values_" in feature && "features" in feature["values_"] && Array.isArray(feature["values_"]["features"]) && feature["values_"]["features"].length > 0 && "values_" in feature["values_"]["features"][0]
    && "geometry" in feature["values_"]["features"][0]["values_"] && feature["values_"]["features"][0].getGeometry().getType() == "Point" ){
      type = "Point"
    }
    // if(feature && "values_" in feature && "geometry" in feature["values_"] && "flatCoordinates" in feature["values_"]["geometry"] && feature["values_"]["geometry"]["flatCoordinates"].length == 2){
    //     type = "Point"
    // }
    if (feature && "values_" in feature && "geometry" in feature["values_"]  && "geometry" in feature["values_"]  && "type" in feature["values_"].geometry) {
      type = feature["values_"].geometry.type;
  }
    return type;
  }
  export function handleMapPointerMove(map, e, layer, tooltip,tooltipRef,  strokeColor,strokeHoverColor,  strokeWidth,   fillColor, fillPolygonsOpacity, fillPointsOpacity, getBgColorFromFeature=false){
    const view = map.getView();
    const currentZoom = view.getZoom();
    if (currentZoom < BREAKPOINT_ZOOM){
       return true;
    }
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);
    map.getTargetElement().style.cursor = hit ? "pointer" : "";
    if(highlightedFeature){
      const geometryType =  getFeatureType(highlightedFeature);
      let style;
      if( geometryType != "Point") {
        style = createGeometryStyle(strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, highlightedFeature, getBgColorFromFeature, false);
      }else{
        //const features =  highlightedFeature.get('features');
        //const size = features.length;
        //style = createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillPointsOpacity, getBgColorFromFeature);

      }
      highlightedFeature.setStyle(style);
      highlightedFeature= null;
    }
    const feature = map.forEachFeatureAtPixel(pixel, function(feature) {
      return feature;
    });
    if(getBgColorFromFeature != false){
      const geometryType =  getFeatureType(feature);
      if(geometryType != "Point"){
        const highlightStyle = createGeometryStyle(strokeHoverColor, strokeWidth, fillColor, fillPolygonsOpacity, feature, getBgColorFromFeature, true);
        if (feature) {
          feature.setStyle(highlightStyle);
          highlightedFeature = feature;
        }
      }
     
    }
   
    // Tooltip info disabled
    const TOOLTIP_DEFAULT_TEXT = "No hay datos para esta geometría";
    const tooltipIsEnabled = true;
    if (feature && tooltipIsEnabled) {
        let type = getFeatureType(feature);
        //if(type == "Point"){
          let tooltipText = feature.get("tooltip");
          if(layer.getTooltip != false){
            tooltipText = layer.getTooltip(feature);
          }
           // Recupera el tooltip asociado a la feature
          if(!tooltipText ){
            tooltipText = TOOLTIP_DEFAULT_TEXT;
          }
          tooltipRef.current.innerHTML = tooltipText;
          tooltip.setPosition(e.coordinate);
       // }
        
    } else {
        tooltipRef.current.innerHTML = "";
        tooltip.setPosition(undefined);
    }
  }

  export function handleClickNoFeature(map, isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath, drawEnabled) {
    if(isGetCoordinatesOnClickEnabled | drawEnabled){
      evt.stopPropagation();
      const pixel = map.getEventPixel(evt.originalEvent);
      var coordinates = map.getCoordinateFromPixel(pixel);
      console.log("Enviando mapclick event", coordinates);
      var evt = new CustomEvent("mapClick", {"detail":{"coordinates":coordinates}});
      document.dispatchEvent(evt);
      addMarkerToMap(map, coordinates, pointMarker, assetsBasePath);
      return
    }
    const view = map.getView();
    const currentZoom = view.getZoom();
    const maxZoom = 20; // Puedes ajustar este valor según tus necesidades
  
    // Si el zoom actual es menor que el máximo permitido, hacemos un pequeño zoom in
    const newZoom = currentZoom < maxZoom ? currentZoom + 1 : currentZoom;
  
    // Anima la vista para centrarla en las coordenadas del clic y ajustar el zoom
    view.animate({
      center: evt.coordinate,
      zoom: newZoom,
      duration: 350 // Duración de la animación en milisegundos, ajusta según necesites
    });
  }
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }
  export function addMarkerToMap(map, coordinates, pointMarker, assetsBasePath){
    if(pointMarker[0]){
      map.removeLayer(pointMarker[0]);
    }
    let iconFeature, vectorSource;
    let basePath = assetsBasePath ? assetsBasePath : process.env.PUBLIC_URL+"/images/"
    let iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          scale:0.02,
          src: basePath+"marker.png"
        })
    });
    //     size: [36, 36Z],
    //     //scale: 0.5,
    //     opacity: 0.75,
    //     src: basePath+"marker.png"
    //   })
    // });
    iconFeature = new Feature({
      geometry: new Point(coordinates)
    });
    iconFeature.setStyle(iconStyle);

    vectorSource = new VectorSource({
      features: [iconFeature]
    });

    pointMarker[0] = new VectorLayer({
      source: vectorSource
    });
    map.addLayer(pointMarker[0]);
  }
  export async function handleMapClick(map, evt, overlay,  setModalBody, setIsModalOpen, layer_element, mustShowOnClickFeature, isGetCoordinatesOnClickEnabled, pointMarker, assetsBasePath, drawEnabled) {
    // Recupera todas las features en la posición clickeada
    console.log("Click")
    const zoom = map?.getView().getZoom();

    if (zoom < BREAKPOINT_ZOOM | drawEnabled){
      handleClickNoFeature(map,isGetCoordinatesOnClickEnabled,  evt, pointMarker, assetsBasePath, drawEnabled);
      return
    }
    evt.stopPropagation();
    const pixel = map.getEventPixel(evt.originalEvent);
    var pointClicked = map.getCoordinateFromPixel(pixel);

    let feature = map.forEachFeatureAtPixel(pixel, function(feature) {
      return feature;
    });
    if(feature){
      if("values_" in feature && "features" in feature["values_"]){
        if( feature["values_"]["features"].length == 1){
          feature = feature["values_"]["features"][0];
        }else{
          //hay más de una feature
          let features = feature["values_"]["features"];
          const sameLocation =  features.every((f) => {
            return (
              f.getGeometry().getCoordinates()[0] === features[0].getGeometry().getCoordinates()[0] &&
              f.getGeometry().getCoordinates()[1] === features[0].getGeometry().getCoordinates()[1]
            );
          });
          if(sameLocation){
            feature = feature["values_"]["features"][0];
          }else{
            if(feature["values_"]["features"].length <10){ // lógica para múltiples features en el click. Más cercana.
              let features = feature["values_"]["features"]
              features.sort((a, b)=>{
                const pa = getCenter(a.getGeometry().getExtent());
                const pb = getCenter(b.getGeometry().getExtent());
                const da = getDistanceFromLatLonInKm(pa[0], pointClicked[0], pa[1], pointClicked[1]);
                const db = getDistanceFromLatLonInKm(pb[0], pointClicked[0], pb[1], pointClicked[1]);
                //console.log("Check order",a.getGeometry(),b.getGeometry(), pa, pb,pointClicked,  da, db,  da > db )
                return da - db;
              })
            //  console.log("features", features);
              feature = features[0];
            }
            // no son la misma feature
            
          }
        }
      } 
      console.log("Feature click", mustShowOnClickFeature, feature)
        if(mustShowOnClickFeature){
          const r = layer_element.getModalBody(feature);
          Promise.resolve(r).then(result =>{
            if (result){
              console.log("Pre modal body", result)
              setModalBody(result);
              setIsModalOpen(true);
            }else if(!isGetCoordinatesOnClickEnabled){
              handleClickNoFeature(map, isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath, drawEnabled);
            }
          }

          )
          
        }else{
          var evt = new CustomEvent("featureClick", {"detail":{"feature":feature}});
          document.dispatchEvent(evt);
        }
       
       

        
    
    }else {
      handleClickNoFeature(map,isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath);
    }
  }

  export function getVectorLayerFromPolygonVectorSources(vectorLayers, vectorSources, allPolygons, strokeColor,  strokeWidth, fillColor, fillOpacity, getBgColorFromFeature) {
    
    vectorLayers = vectorSources.map((source, index) => {
      let bgColor = getBgColorFromFeatureGeneric(allPolygons, index, getBgColorFromFeature);
      let vl =  new VectorLayer({
        source: source,
        projection: source.projection,
        style: new Style({
          stroke: new Stroke({
            color: strokeColor || DEFAULT_STROKE_COLOR,
            width: strokeWidth || DEFAULT_STROKE_WIDTH,
          }),
           fill: new Fill({
             color: `rgba(${bgColor || fillColor || "0, 0, 0"}, ${fillOpacity || 1})`,
           }),
        }),
      });
      return vl;
    });
    return vectorLayers;
  }

  // export function getVectorLayerFromPointVectorSources(vectorLayers, vectorSources, allPoints, strokeColor, strokeWidth,  fillColor, fillOpacity, map) {
  //   const source = new VectorSource({
  //     features: allPoints,
  //   });
  //   let pointsDistance = 15;
  //   let minPointsDistance = 25;
  //   const clusterSource = new Cluster({
  //     distance: pointsDistance,
  //     minDistance: minPointsDistance,
  //     source: source,
  //     geometryFunction: (feature) => {
  //       return new Point(getCenter(feature.getGeometry().getExtent()));
  //     },
      
  //   });
  //   const clusters = new VectorLayer({
  //     source: clusterSource,
  //     style: function (feature) {
  //      // console.log("Map ", map)
  //       const zoom = map != null ? map.getView().getZoom() : 20;
  //      // console.log("feature", feature)
  //       const size = feature.get('features').length;
  //       let features = null;
  //       let sameLocation = false;
       
  //       if(size > 1 && size < 15){
  //         features = feature.get('features');
  //         sameLocation =  features.every((f) => {
  //           return (
  //             f.getGeometry().getCoordinates()[0] === features[0].getGeometry().getCoordinates()[0] &&
  //             f.getGeometry().getCoordinates()[1] === features[0].getGeometry().getCoordinates()[1]
  //           );
  //         });
           
          
  //       }
        
  //       let geometryRepresentation = false;
  //       if(sameLocation){
  //         const index = feature.get('features').indexOf(feature);
  //         ;
  //         features.every((f) => {
  //           let c0 = f.getGeometry().getCoordinates()[0];
  //           let c1 =f.getGeometry().getCoordinates()[1];
  //           const f_index = features.indexOf(f) ;
  //           const angle = (index / size) * 2 * Math.PI;
  //           const offset = 0.00015; // distancia para separar los puntos
  //           const offsetX = offset * Math.cos(angle);
  //           const offsetY = offset * Math.sin(angle)


  //           f.getGeometry().setCoordinates([c0+offsetX, c1+offsetY])
  //         })
          
  //       }
  //       let style = false;
  //       let status = false;
  //       let bgColor = false;
  //       let txtColor = "0, 0, 0";
        
  //       if(size == 1){
  //         let feat = feature.get('features')[0]
  //         if(feat && "values_" in feat && feat["values_"] && "status" in feat["values_"]){
  //           status = feat["values_"]["status"];
  //           bgColor = getBgColorFromStatus(status);
  //           txtColor = getTextColorFromStatus(status);
  //           //strokeColor= getStrokeColorFromStatus(status);
            
  //         }
  //       }
        
  //      // console.log("bgColor", bgColor)
  //      // console.log("txtColor", txtColor)
  //      // console.log("status",size,  status, feature, feature.get('features'))
        
  //       let backgroundColor =  size > 1? "#FCFCFC" : `rgba(${bgColor || fillColor || "0, 0, 0"}, ${fillOpacity || 1})`
  //      // console.log("Zoom", zoom)
  //       if(zoom < 10){
  //         return new Style({
  //           stroke: new Stroke({
  //             color: strokeColor || DEFAULT_STROKE_COLOR,
  //             width: strokeWidth || DEFAULT_STROKE_WIDTH,
  //           }),
  //            fill: new Fill({
  //              color: `rgba(${bgColor || fillColor || "0, 0, 0"}, ${fillOpacity || 1})`,
  //            }),
  //         })
  //       }else  if (!style) {

  //         return new Style({
  //           image: new CircleStyle({
  //             radius: 10,
  //             stroke: new Stroke({
  //               color: strokeColor || DEFAULT_STROKE_COLOR,
  //               width: strokeWidth || DEFAULT_STROKE_WIDTH,
  //             }),
  //             fill:  new Fill({
  //                        color: backgroundColor,
  //                      }),
  //           }),
  //           text:  new Text({
  //             text: size > 1? size.toString(): "",
  //             fill: new Fill({
  //                        color:`rgba(${txtColor}, 1)` , // size > 1? "#000000" : `rgba(${txtColor || "0, 0, 0"}, 1)`,
  //                      }),
  //           }) ,
  //         });
  //       }
  //       return style;
  //     },
  //     // style: function (feature) {
  //     //   const size = feature.get('features').length;
  //     //   let style = styleCache[size];
  //     //   if (!style) {
  //     //     style = new Style({
  //     //       image: new CircleStyle({
  //     //         radius: 10,
  //     //         stroke: new Stroke({
  //     //           color: '#fff',
  //     //         }),
  //     //         fill: new Fill({
  //     //           color: '#3399CC',
  //     //         }),
  //     //       }),
  //     //       text: new Text({
  //     //         text: size ? size.toString() : "",
  //     //         fill: new Fill({
  //     //           color: '#fff',
  //     //         }),
  //     //       }),
  //     //     });
  //     //     styleCache[size] = style;
  //     //   }
  //     //   return style;
  //     // },
  //   });
  //   //console.log("Clusters", clusters);

  //   return [clusters];
  // }

// Función principal para obtener la capa vectorial
export function getVectorLayerFromPointVectorSourcesV2(vectorLayers, vectorSources, allPoints, strokeColor, strokeWidth, fillColor, fillOpacity, map, getBgColorFromFeature) {
  
  const source = new VectorSource({ features: allPoints });
  
  const clusterSource = createClusterSource(source);
  let numberOfFeatures = 0;
  if(map != null && map != undefined){
    numberOfFeatures = getNumberOfVisibleFeatures(map);
  }
  const clusters = new VectorLayer({
      source: clusterSource,
      style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)
  });

  return [clusters];
}
export function updateLayerStyle(map, vectorLayer, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature) {
  let numberOfFeatures = 0;
  if(vectorLayer && vectorLayer != [] && vectorLayer != false && vectorLayer.length > 0 && map!= null && map != undefined){
    //console.log("vectorLayer", vectorLayer)
    const zoom = map?.getView().getZoom()?? 20;
    numberOfFeatures = getNumberOfVisibleFeatures(map);
    const currentClusterSource = vectorLayer[0].getSource();
   // console.log(" vectorLayer[0]", vectorLayer[0])
   // console.log(" currentClusterSource", currentClusterSource)
   // console.log(" getSource", currentClusterSource.getSource())
    if(zoom < BREAKPOINT_ZOOM && currentClusterSource instanceof Cluster){
      // Asegurarse de que el source actual es un ClusterSource
          vectorLayer[0].setSource(createClusterSource( currentClusterSource.getSource(),100, 50))
    }else if (currentClusterSource instanceof Cluster){
          vectorLayer[0].setSource( createClusterSource( currentClusterSource.getSource(),0, 0));
         // vectorLayer[0].setSource(currentClusterSource.getSource())
      }
    }
    
    vectorLayer[0].setStyle(feature => {
      return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)

    });
}

function generateVectorSource(source, getBgColorFromFeature, strokeColor, strokeWidth, fillColor, fillOpacity){

    let bgColor = getBgColorFromFeature(source)
    return  new VectorLayer({
      source: source,
      projection: source.projection,
      style: new Style({
        stroke: new Stroke({
          color: strokeColor || DEFAULT_STROKE_COLOR,
          width: strokeWidth || DEFAULT_STROKE_WIDTH,
        }),
         fill: new Fill({
           color: `rgba(${bgColor || fillColor || "0, 0, 0"}, ${fillOpacity || 1})`,
         }),
      }),
    });
}
// Función para crear la fuente del clúster
function createClusterSource(source, distance = 100, minDistance = 5) {
  return new Cluster({
      distance: distance,
      minDistance: minDistance,
      source: source,
      geometryFunction: feature =>{
        try{
          return new Point(getCenter(feature.getGeometry().getExtent()))
        }catch{
          return feature.getGeometry();
        }
      } 
  });
}
const obtenerVectorSources = (map) => {
  const vectorSources = [];
  map.getLayers().forEach(layer => {
    if (layer instanceof VectorLayer) {
      vectorSources.push(layer.getSource());
    }
  });
  return vectorSources;
};
function getNumberOfVisibleFeatures (map){
  let count = 0;
  
 
 // console.log("map",  map.getLayers().getArray()[1]);
 // console.log("map2",   map.getLayers().getArray()[1]);
  //const vectorSources = obtenerVectorSources(map);
  const extent = map.getView().calculateExtent(map.getSize());
  if (map != undefined && map.getLayers() != undefined) {
    const layers = map.getLayers().getArray();
    if (layers.length > 1) {
        const vectorLayer = layers[1]; // Suponiendo que tu VectorLayer está en esta posición
        if (vectorLayer instanceof Vector) {
            const vectorSource = vectorLayer.getSource();
            if (vectorSource.getFeatures().length > 0) {
                // Tu lógica aquí
                vectorSource.forEachFeature((feature) => {
                  if (feature.getGeometry().intersectsExtent(extent)) {
                      count++;
                  }
              });
            }
        }
    }
    if (layers.length > 2) {
      const vectorLayer = layers[2]; // Suponiendo que tu VectorLayer está en esta posición
      if (vectorLayer instanceof Vector) {
          const vectorSource = vectorLayer.getSource();
          if (vectorSource.getFeatures().length > 0) {
              // Tu lógica aquí
              vectorSource.forEachFeature((feature) => {
                if (feature.getGeometry().intersectsExtent(extent)) {
                    count++;
                }
            });
          }
      }
  }
}

 // console.log("getNumberOfVisibleFeatures", count)
  
  return count;
}

// Función de estilo
function styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures) {
  //console.log("feature", feature)
  
  
  const zoom = map?.getView().getZoom()?? 20;
  let breakpointZoomOut = zoom <= BREAKPOINT_ZOOM;
  //const numberOfFeatures = getNumberOfVisibleFeatures(map);
  let breakpointNumberOfFeatures = numberOfFeatures <=1500
 const geometryType =  getFeatureType(feature);
// console.log("Breakpoint", geometryType, breakpointZoomOut, feature)

  if (!breakpointZoomOut && geometryType != "Point") { 
      return createGeometryStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature);
  } else {
     const features =  feature.get('features');
     const size = features.length;
      return createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature);
  }
}

// Estilo para geometrías (zoom < 10)
function createGeometryStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature, isHigtlighted = false) {
  //console.log("Aquí")
  try{
    if(!feature){
      return 
    }
    const bgColor = getBgColorFromFeature(feature);
   // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
    let backgroundColor =  bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
   // console.log("backgroundColor2", backgroundColor);
   //if(feature.getGeometry())
    let style =  new Style({
        stroke: new Stroke({
            color: strokeColor || DEFAULT_STROKE_COLOR,
            width: isHigtlighted ?(strokeWidth*STROKE_MULTIPLY || DEFAULT_STROKE_WIDTH*STROKE_MULTIPLY): strokeWidth || DEFAULT_STROKE_WIDTH
        }),
        fill: new Fill({
            color:backgroundColor
        })
    });
    var features = feature.get('features');
    console.log("Features", features)
    if(features && features.length && features.length>1){
      //console.log("MAS DE UNA FEATURE", features)
      let result = []
      for(let element of features){
        let styleCopy = style.clone();
        styleCopy.setGeometry(element.getGeometry());
        result.push(styleCopy)
      }
      //console.log("Result", result)
      return result;
    }
    style.setGeometry(features[0].getGeometry());
    return style;
  }catch(e){
    console.error("Problema con los estilos")
    return
  }
 
}

// Estilo para clústeres (zoom >= 10)
function createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature) {
  const bgColor = getBgColorFromFeature(feature);
 // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
  let backgroundColor = size > 1 ? "#FCFCFC" : bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor || DEFAULT_FILL_COLOR}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
 // console.log("backgroundColor", backgroundColor)
  return new Style({
      image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
              color: strokeColor || DEFAULT_STROKE_COLOR,
              width: strokeWidth || DEFAULT_STROKE_WIDTH
          }),
          fill: new Fill({ color: backgroundColor })
      }),
      text: new Text({
          text: size > 1 ? size.toString() : '',
          fill: new Fill({
              color: 'rgba(0, 0, 0, 1)'
          })
      })
  });
}
  export function tooltipInit(tooltipRef) {
    return new Overlay({
      element: tooltipRef.current,
      positioning: "bottom-center",
      stopEvent: false,
      offset: [0, -10],
    });
  }
  export async function addGeolocation(map,geolocation, geoPositionPoint, setGeoPositionPoint, geoAccuraccyGeometry, setGeoAccuraccyGeometry){
    
    // geolocation.setTracking(true);
    let positionFeature = new Feature();
    let accuracyFeature = new Feature();
    if(geoAccuraccyGeometry){
      accuracyFeature.setGeometry(geoAccuraccyGeometry);
    }
    try{
      geolocation.un("change:position")
    }catch(e){
      console.warn("Error en el unlisten de geoposicion")
    }
    geolocation.on('change:accuracyGeometry', function () {
      let accuracyGeometry = geolocation.getAccuracyGeometry();
      accuracyFeature.setGeometry(accuracyGeometry);
      setGeoAccuraccyGeometry(accuracyGeometry);
    });
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );
    geolocation.on('error', (error) => {
      console.log("Problema obteniendo la geolocalización", error)
      
    });
    if(geoPositionPoint){
      positionFeature.setGeometry(geoPositionPoint ? new Point(geoPositionPoint) : null);
    };

    geolocation.once('change:position', async function () {

      let coordinates = geolocation.getPosition();
      if(coordinates){
        console.log("Actualizando coordenadas", coordinates)
        setGeoPositionPoint(coordinates);
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      }
      console.log("Centrando mapa en posición")
      // map.getView().setCenter(coordinates);
      // map.getView().setZoom(17)
      console.log("geopositionCoords ONCE event", coordinates, geolocation_first_time, performance.now() - startTime )

      map.getView().animate({
        center: coordinates,
        zoom: 17,
        duration: 350 // Duración de la animación en milisegundos, ajusta según necesites
      });
    })
    geolocation.on('change:position', async function () {
      let coordinates = geolocation.getPosition();
      if(coordinates){
        console.log("Actualizando coordenadas", coordinates)
        setGeoPositionPoint(coordinates);
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      }
    
      console.log("geopositionCoords event", coordinates, geolocation_first_time, performance.now() - startTime )

    });
    

    new VectorLayer({
      map: map,
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
    });
  }
  export function overlayInit( popupRef) {
    return new Overlay({
      element: popupRef.current,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
  }
  export function mapInit( mapRef, tooltip, overlay, baseLayer, polygonVectorLayers,pointVectorLayers, combinedCenter, projection, scaleLineControl, drawEnabled, drawSource, oldZoom=false, oldCoordinates=false) {
    //console.log("pointVectorLayers", pointVectorLayers, pointVectorLayers.iterable)
   // console.log("baseLayer", baseLayer, baseLayer.iterable)
    //console.log("polygonVectorLayers", polygonVectorLayers, polygonVectorLayers.iterable)
    let drawVector = [];
    if(drawEnabled){
      drawVector = [new VectorLayer({
        source: drawSource,
      })];
  }
    let map = new Map({
      target: mapRef.current,
      overlays: [tooltip, overlay],
      layers: [baseLayer, ...(polygonVectorLayers  ? polygonVectorLayers : []), ...(pointVectorLayers   ? pointVectorLayers : []), ...(drawEnabled ? drawVector : [])],
      view: new View({
        center: oldCoordinates || combinedCenter,
        projection: projection,
        zoom: oldZoom || polygonVectorLayers || pointVectorLayers ? 10 : 5,
      }),
      controls: defaults({
        zoomOptions: {
          className: 'ol-zoom',
          target: undefined,
          position: 'bottom-right' // Esta opción no es estándar en OpenLayers pero te guiaré sobre cómo implementarla a continuación.
        }
      }),
    });
    map.addControl(scaleLineControl);
    

    map.addOverlay(overlay);
    return map;
  }
  
  

  export function readGeoJsons(geojsons, projection = "EPSG:4326"){
    const parsedGeojsons = JSON.parse(geojsons); // Parsear el string a lista de objetos
    const vectorSources = [];
    const markerSources = [];
    const allPolygons = [];
    const allPoints = [];
    let crs_readed = projection;
    // Recorrer y parsear cada geojson y añadirlo a vectorSources y allPolygons
    for (let readedFeature of parsedGeojsons) {
      let features = null;
      if("features" in readedFeature){
        
        // if("crs" in readedFeature && "properties" in readedFeature["crs"] && "name" in readedFeature["crs"]["properties"]){
        //   let splitted = readedFeature["crs"]["properties"]["name"].split("EPSG::");
        //   if(splitted.length>1){
        //     crs_readed = "EPSG:" + splitted[1]
        //   }

        // }
        for(let feature of readedFeature["features"]){
         
          const {features, geometryType} = processFeature(feature, crs_readed)
          //console.log("Geometry type : ", geometryType)
          if(geometryType == "Polygon" || geometryType == "MultiPolygon"){
            vectorSources.push(
                new VectorSource({
                  features: features,
                })
              );
              //console.log("Tooltip", g, features)
              allPolygons.push(...features); //.flat()?
          }else if(geometryType == "Point"){
            markerSources.push(new VectorSource({
                features: features,
              }))
              allPoints.push(...features);
          }
        }
      }else{
        const {features, geometryType}  = processFeature(readedFeature)
        if(geometryType == "Polygon" || geometryType == "MultiPolygon"){
            vectorSources.push(
                new VectorSource({
                  features: features,
                })
              );
              //console.log("Tooltip", g, features)
              allPolygons.push(...features); //.flat()?
          }else if(geometryType == "Point"){
            markerSources.push(new VectorSource({
                features: features,
              }))
              allPoints.push(...features);
          }
      }
      
    }
    return {allPolygons, allPoints, vectorSources, markerSources};
  }
  export function processFeature(feature, projection){
    let features = null;
    let geometryType = "Polygon";
    if (feature && "geometry" in feature && "type" in feature.geometry) {
        geometryType = feature.geometry.type;
    }

    if("properties" in feature){
     //// console.log("Read geom with coords", feature);
      features = new GeoJSON().readFeatures(feature, {
        dataProjection: feature["properties"]["projection"],
        featureProjection: projection,
        tooltip : feature["properties"]["tooltip"]
      });
    }else{
     //// console.log("Read geom withouth coords", feature);
      features = new GeoJSON().readFeatures(feature);
    } 
    return {features, geometryType};
  }

  export function scaleLineInit() {

    return new ScaleLine({
      className: "ol-scale-line",
      units: "metric",
      title:"Escala",
      // bar: true,  // muestra una barra en vez de texto
      // steps: 2,  // número de subdivisiones en la escala (sólo aplica si bar=true)
      // text: true,  // muestra el texto junto con la barra
      minWidth: 64,
    });
  }

  export function baseLayerInit(selectedValue = "light_all") {
    const styles = [
        "light_all",
        "dark_all",
        "light_nolabels",
        "light_only_labels",
        "dark_nolabels",
        "dark_only_labels",
        "rastertiles/voyager",
        "rastertiles/voyager_nolabels",
        "rastertiles/voyager_only_labels",
        "rastertiles/voyager_labels_unde",
      ];
      // "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      // "http://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      let sourceBaseMap = getSourceBaseMapFromName(selectedValue);
      return new TileLayer({
      source: sourceBaseMap,
    });
  }
  
