
import axios from "axios";
import jwt_decode from "jwt-decode";

export const get_agroservice_features = async (userHeader, token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice/features_list";
    let config = {
      method        : "get",
      url           : URL_BASE,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        "Content-Type"                : "*",
        "Access-Control-Allow-Headers": "*",
        Accept                        : "*/*",
      },
    };
    return axios.request(config).then((response) => {
      let data = response.data;

      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};


export const get_agroservice_list = async (userHeader, token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice/list";
    let config = {
      method        : "get",
      url           : URL_BASE,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        "Access-Control-Allow-Headers": "*",
        "Content-Type"                : "*",
        Accept                        : "*/*",
      },
    };
    return axios.request(config).then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};



export const get_agroservice = async (id, userHeader, token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice/";
    let config = {
      method        : "get",
      maxBodyLength : Infinity,
      url           : URL_BASE + id,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        "Content-Type"                : "*",
        "Access-Control-Allow-Headers": "*",
        Accept                        : "*/*",
      },
    };
    return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};

export const create_agroservice = async (data, userHeader, token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice";
    let config = {
      method        : "post",
      maxBodyLength : Infinity,
      url           : URL_BASE,
      data          : data,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        // "Content-Type"                : "*",
        "Access-Control-Allow-Headers": "*",
        Accept                        : "*/*",
      },
    };
    return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};


export const update_agroservice = async (data, id, userHeader, token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice/";
    let config = {
      method        : "put",
      maxBodyLength : Infinity,
      url           : URL_BASE+id,
      data          : data,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        "Access-Control-Allow-Headers": "*",
        Accept                        : "*/*",
      },
    };
    return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};

export const delete_agroservice = async (id, userHeader, token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice/";
    let config = {
      method        : "delete",
      maxBodyLength : Infinity,
      url           : URL_BASE + id,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        "Content-Type"                : "*",
        "Access-Control-Allow-Headers": "*",
        Accept                        : "*/*",
      },
    };
    return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};


export const get_pdfs = async (id, userHeader, token) => {
  let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/form/agroservice/pdf/";
  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + id,
    headers: {
      us                            : userHeader,
      Authorization                 : token,
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };
  return axios
  .request(config)
  .then((response) => {
    let data = response.data;
    return data;
  })
  .catch((error) => {
   // console.log(error);
    return false;
  });
};
