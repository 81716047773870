import React, {useEffect, useState} from "react";
import { FormControl, InputLabel, ListSubheader, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { hexToRgb } from "../../Objects/Layers/LayerCommons";
import LayerPublicAmianticTextInput from "../../Objects/Layers/LayerPublicAmianticTextInput";

var info_field = (
    label,
    _data,
    fixed_decimals = false,
    is_date=false,
    backgroundColor = false,
    suffix = "",
    full_data = {},
    key_state = "",
    setFullData = ()=>{},
    key_states = {}
  ) => {
      
   // console.log(_data);
    if (fixed_decimals && _data !== undefined && _data !== null) {
      //_data = _data.toFixed(fixed_decimals).toString();
      _data = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,

      }).format(_data).toString();
    }
    if(is_date  && _data !== undefined && _data !== null){
      _data = new Date(_data).toISOString().slice(0, 10);
    }
    return (
<>
      <TextField
        style={{
          width: "90%",
         // marginTop: "0.8rem",
          backgroundColor: backgroundColor,
        }}
       // sx={{style:{width:"100%"}}}
       // size="small"
        label={label}
        value={key_states[key_state]["value"]}
        
        onChange={_value => {
            console.log("On change", _value.target.value, full_data)
            let d = full_data;
            setFullData({...full_data, [key_state]:_value.target.value})
            key_states[key_state]["set"]( _value.target.value)

        }}
        //readonly
      />
      <br/>
      </>
    );
};

const crop_selector=(label, value, key_state, full_data, setFullData, key_states, backgroundColor=false)=>{
  console.log("Abriendo crop key_states", key_states)
  console.log("Abriendo crop key_state",  key_state)
  console.log("Abriendo crop full_data",   full_data)
  console.log("Abriendo crop value",  value)
  return <>
 <FormControl style={{ width: '90%' }}>
      {/* Aquí agregas la etiqueta */}
      <InputLabel style={{backgroundColor:"white"}} id="grouped-select-label">Seleccione un tipo de cultivo</InputLabel>  <Select 
  // defaultValue={""}
  displayEmpty={true}
  style={{
    width: "100%",
   // marginTop: "0.8rem",
    backgroundColor: backgroundColor,
  }}
  value={key_states[key_state]["value"]} 
  id="grouped-select" 
  //label={label} 
  labelId="grouped-select-label"
  onChange={(_value=>{
    console.log("On change", _value.target.value, full_data)
    let d = full_data;
    setFullData({...full_data, [key_state]:_value.target.value})
    key_states[key_state]["set"]( _value.target.value)

  })} 
  >
    <MenuItem disabled value="">
            <em>Seleccione un tipo de cultivo</em>
    </MenuItem>
    <ListSubheader> Cultivos Herváceos:</ListSubheader>
      <MenuItem value="Arroz" > Arroz</MenuItem>
      <MenuItem value="Trigo" > Trigo</MenuItem>
      <MenuItem value="Maíz" > Maíz</MenuItem>
      <MenuItem value="Cebada" > Cebada</MenuItem>
      <MenuItem value="Avena" > Avena</MenuItem>
      <MenuItem value="Centeno" > Centeno</MenuItem>
      <MenuItem value="Sorgo" > Sorgo</MenuItem>
      <MenuItem value="Caña de azúcar" > Caña de azúcar</MenuItem>
      <MenuItem value="Caña de maíz" > Caña de maíz</MenuItem>
      <MenuItem value="Papa" > Papa</MenuItem>
      <MenuItem value="Batata" > Batata</MenuItem>
      <MenuItem value="Yuca" > Yuca</MenuItem>
      <MenuItem value="Remolacha" > Remolacha</MenuItem>
      <MenuItem value="Zanahoria" > Zanahoria</MenuItem>
      <MenuItem value="Cebolla" > Cebolla</MenuItem>
      <MenuItem value="Lechuga" > Lechuga</MenuItem>
      <MenuItem value="Espinaca" > Espinaca</MenuItem>
      <MenuItem value="Tomate" > Tomate</MenuItem>
      <MenuItem value="Pepino" > Pepino</MenuItem>
      <MenuItem value="Calabacín" > Calabacín</MenuItem>
      <MenuItem value="Pimiento" > Pimiento</MenuItem>
      <MenuItem value="Berenjena" > Berenjena</MenuItem>
      <MenuItem value="Frijoles" > Frijoles</MenuItem>
      <MenuItem value="Guisantes" > Guisantes</MenuItem>
      <MenuItem value="Lentejas" > Lentejas</MenuItem>
      <MenuItem value="Soja" > Soja</MenuItem>
      <MenuItem value="Girasol" > Girasol</MenuItem>
      <MenuItem value="Colza" > Colza</MenuItem>
      <MenuItem value="Algodón" > Algodón</MenuItem>
      <MenuItem value="Caña de azúcar" > Caña de azúcar</MenuItem>
   
    <ListSubheader>Cultivos Leñosos:</ListSubheader>
      <MenuItem value="Manzana">Manzana</MenuItem>
      <MenuItem value="Pera">Pera</MenuItem>
      <MenuItem value="Cereza">Cereza</MenuItem>
      <MenuItem value="Durazno">Durazno</MenuItem>
      <MenuItem value="Ciruela">Ciruela</MenuItem>
      <MenuItem value="Naranja">Naranja</MenuItem>
      <MenuItem value="Limón">Limón</MenuItem>
      <MenuItem value="Mandarina">Mandarina</MenuItem>
      <MenuItem value="Pomelo">Pomelo</MenuItem>
      <MenuItem value="Uva">Uva</MenuItem>
      <MenuItem value="Kiwi">Kiwi</MenuItem>
      <MenuItem value="Mango">Mango</MenuItem>
      <MenuItem value="Plátano">Plátano</MenuItem>
      <MenuItem value="Aguacate">Aguacate</MenuItem>
      <MenuItem value="Coco">Coco</MenuItem>
      <MenuItem value="Nuez">Nuez</MenuItem>
      <MenuItem value="Almendra">Almendra</MenuItem>
      <MenuItem value="Avellana">Avellana</MenuItem>
      <MenuItem value="Pistacho">Pistacho</MenuItem>
      <MenuItem value="Oliva">Oliva</MenuItem>
      <MenuItem value="Café">Café</MenuItem>
      <MenuItem value="Cacao">Cacao</MenuItem>
      <MenuItem value="Vid (para la producción de vino)">Vid (para la producción de vino)</MenuItem>
      <MenuItem value="Higo">Higo</MenuItem>
      <MenuItem value="Granada">Granada</MenuItem>
      <MenuItem value="Caqui">Caqui</MenuItem>
      <MenuItem value="Guayaba">Guayaba</MenuItem>
      <MenuItem value="Papaya">Papaya</MenuItem>
      <MenuItem value="Piña">Piña</MenuItem>
      <MenuItem value="Melocotón">Melocotón</MenuItem>
   

  </Select>
  </FormControl>

  <br/>
  </>

}
  
const background = (feature) => {
  const post2002Color = hexToRgb("#00ff00");
  const pre2002Color = hexToRgb("#ff8000");
  const defaultColor = hexToRgb("#0000FF");
  return defaultColor;
};

export default function FincaModal({data, setData}){
   console.log("Finca modal", data)
   const [formName        , setFormName] = useState(data["name"])
   const [formCrop_type   , setFormCrop_type] = useState(data["crop_type"])
   const [formCrop_variety, setFormCrop_variety] = useState(data["crop_variety"])
   const [formAddress     , setFormAddress] = useState(data["address"])
   const [formMunicipe    , setFormMunicipe] = useState(data["municipe"])
   const [formOwner       , setFormOwner] = useState(data["owner"])
   const [formNotes       , setFormNotes] = useState(data["notes"])
   let key_states =  {
    "name" :         {"value":formName        , "set":setFormName},
    "crop_type" :    {"value":formCrop_type   , "set":setFormCrop_type},
    "crop_variety" : {"value":formCrop_variety, "set":setFormCrop_variety},
    "address" :      {"value":formAddress     , "set":setFormAddress},
    "municipe" :     {"value":formMunicipe    , "set":setFormMunicipe},
    "owner" :        {"value":formOwner       , "set":setFormOwner},
    "notes":         {"value":formNotes       , "set":setFormNotes},
   //"Superficie de amianto":
  }
   
  useEffect(()=>{
    setFormName(data["name"]);
    setFormCrop_type(data["crop_type"]);
    setFormCrop_variety(data["crop_variety"]);
    setFormAddress(data["address"]);
    setFormMunicipe(data["municipe"]);
    setFormOwner(data["owner"]);
    setFormNotes(data["notes"]);
    key_states = {
      "name" :         {"value":formName        , "set":setFormName},
      "crop_type" :    {"value":formCrop_type   , "set":setFormCrop_type},
      "crop_variety" : {"value":formCrop_variety, "set":setFormCrop_variety},
      "address" :      {"value":formAddress     , "set":setFormAddress},
      "municipe" :     {"value":formMunicipe    , "set":setFormMunicipe},
      "owner" :        {"value":formOwner       , "set":setFormOwner},
      "notes":         {"value":formNotes       , "set":setFormNotes},
     //"Superficie de amianto":
    }
  }, [data])
    let data_processed = JSON.parse(JSON.stringify(data));
    const replacements = {
      "name" : "Nombre",
      "crop_type" : "Tipo de cultivo",
      "crop_variety" : "Variedad",
      "address" : "Dirección",
      "municipe" : "Municipio",
      "owner" : "Propietario",
      "notes": "Notas",

     //"Superficie de amianto":
    }

    const links = [
     
    ]
    const units = {
      "area": " m²",
      "perimeter":" m²",
    }
    const is_float = [
      "area", 
      "perimeter",
    ]
    const is_date = []
    for(const key of Object.keys(data)){
      if(!Object.keys(replacements).includes(key)){
        delete data_processed[key];
      }
    }





    return <div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
      <div style={{display:"flex",  flexDirection:"column", flex:"1 1 220px", maxHeight:"70vh", overflowY:"auto", paddingLeft:"1rem", paddingTop:"1rem"}}>
        {Object.keys(replacements).map(key=>{
          let value = data_processed[key];
          let suffix = "";
          if(Object.keys(units).includes(key)){
            suffix=units[key];
          }
          if(key == "crop_type"){
            return crop_selector(replacements[key], value, key, data, setData, key_states)
          }
          if(key == "crop_variety" ){
            if( data_processed["crop_type"] != ""){
              console.log("Mostrando crop_variety", data_processed["crop_type"])
              return info_field(replacements[key], value , is_float.includes(key), is_date.includes(key), false, suffix, data, key, setData, key_states);
            }else{
              return <></>
            }
           
          }
          return info_field(replacements[key], value , is_float.includes(key), is_date.includes(key), false, suffix, data, key, setData, key_states);
        })}
  
        
      </div>
      {/* {catastroImageUrl != "" && <div  style={{display:"flex", flex:"3 1 320px",  maxHeight:"70vh"}}>
       <a href={catastroImageUrl} target='blank'> <img style={{width: "100%", borderRadius: "10px",height:"100%",   objectFit:"cover"}}  src={catastroImageUrl}/></a>
      </div>} */}
    </div>
    //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
}

