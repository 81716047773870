import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


const Description_informe = () => {
    // return <div>
    //     <div>
    //         <h3>
    //             Informe quincenal
    //         </h3>
    //         <p>
    //         Toda la información de monitorización satélite del cultivo es entregada mediante un informe en PDF compuesto por una cartografía de situación de la parcela, un análisis quincenal meteorológico, cartografía quincenal de la vigorosidad y del estado hídrico del cultivo, cartografía de la variabilidad quincenal y anual del cultivo, gráficas estadísticas de la evolución de las parcelas. Además, se incluye el análisis “Vara de medir” con el que poder comparar la situación actual de la vigorosidad del cultivo con campañas anteriores.
    //         </p>
    //         <p>
    //         Incluye el análisis del estado actual del cultivo, su evolución y variabilidad quincenal y en anteriores campañas permite conocer las posibles afecciones que sufre o pudo sufrir la finca (encharcamientos, fallos de riego, etc.), conocer la respuesta del cultivo frente a soluciones o pruebas aplicadas en campo, entender la correlación del comportamiento del cultivo en zonas con diferente productividad, así como el registro de dicha información para compararla con compañas futuras.
    //         </p>
    //         <br/>
    //         <h3>
    //             Informe de hetereogeneidad
    //         </h3>
    //         <p>
    //         Mediante el análisis de la vigorosidad de las últimas campañas se hace entrega de un informe PDF que incluye la zonificación de las áreas con mayor y menor productividad incluyendo el gráfico evolutivo del vigor en la serie temporal completa de estudio.
    //         </p>
    //         <br/>
    //         <h3>
    //             Informe de abonado variable
    //         </h3>
    //         <p>
    //         Informe de zonificación de aplicación de abonado diferencial y capas de datos en formato digital (.shp y .geojson) para usar directamente en el ordenador de abordo de la maquinaria y tener programado el abonado. En caso de disponer del sistema de riego digitalizado, se superpondrá dicha información en las cartografías realizadas con la finalidad de conocer las posibles anomalías en los sectores de riego durante la campaña.

    //         </p>
    //         <br/>
    //     </div>

    // </div>
    return <div>
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <h3>Informe quincenal</h3>
            </AccordionSummary>
            <AccordionDetails>
               <p>Toda la información de <strong>monitorización satélite</strong> del cultivo es entregada mediante un <strong>informe en PDF</strong> compuesto por una cartografía de situación de la parcela, un análisis quincenal meteorológico, cartografía quincenal de la <strong>vigorosidad y del estado hídrico</strong> del cultivo, cartografía de la variabilidad quincenal y anual del cultivo, gráficas estadísticas de la evolución de las parcelas. Además, se incluye el <strong>análisis “Vara de medir”</strong> con el que poder comparar la situación actual de la vigorosidad del cultivo con campañas anteriores.</p>
               <p>Incluye el análisis del <strong>estado actual del cultivo, su evolución y variabilidad quincenal</strong> y en anteriores campañas permite conocer las posibles afecciones que sufre o pudo sufrir la finca (encharcamientos, fallos de riego, etc.), conocer la respuesta del cultivo frente a soluciones o pruebas aplicadas en campo, entender la correlación del comportamiento del cultivo en zonas con diferente productividad, así como el registro de dicha información para compararla con compañas futuras.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <h3>Informe de heterogeneidad</h3>
            </AccordionSummary>
            <AccordionDetails>
                <p>Mediante el <strong>análisis de la vigorosidad</strong> de las últimas campañas se hace entrega de un <strong>informe PDF que incluye la zonificación de las áreas con mayor y menor productividad</strong> incluyendo el gráfico evolutivo del vigor en la serie temporal completa de estudio.</p>
            </AccordionDetails>
        </Accordion>
        <Accordion >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
            >
                <h3>Informe de abonado variable</h3>
            </AccordionSummary>
            <AccordionDetails>
            <p>Informe de zonificación de aplicación de abonado diferencial y <strong>capas de datos en formato digital (.shp y .geojson) para usar directamente en el ordenador</strong> de abordo de la maquinaria y tener programado el abonado. En caso de disponer del sistema de riego digitalizado, se superpondrá dicha información en las cartografías realizadas con la finalidad de conocer las posibles anomalías en los sectores de riego durante la campaña.</p>

            </AccordionDetails>
            <AccordionActions>
                <Button>Cancel</Button>
                <Button>Agree</Button>
            </AccordionActions>
        </Accordion>
    </div>
}
export default Description_informe;