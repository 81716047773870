import {
  ButtonGroup,
  Checkbox,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  createMuiTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import i18next from "i18next";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../AppContext";
import OpenLayersFullAnalysisMap from "../../../OpenLayersFulllAnalysisMap";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import get_geojson, { checkIsTokenOk } from "../../services/municipio_service";
import {
  create_agroservice,
  get_agroservice_features,
  delete_agroservice,
  get_agroservice_list,
  update_agroservice,
  get_pdfs,
} from "../../services/form_service";
import {
  LAYER_PRE_2002,
  LAYER_DATE_CONSTRUCTION,
  LAYER_SUSCEPTIBILITY,
  LAYER_CURRENT_USE,
} from "../../Objects/Layers/LayerClass";
import "./CatastroForm.css";
import OpenLayersMap from "./Location/Catastro_Map";
import en from "./i18n/en";
import es from "./i18n/es";
import FincaModal from "./newFincaModal";
import GenericTextModalConfirmationWithButton from "../../modals/GenericTextModalConfirmationWithButton/GenericTextModalConfirmationWithButton";
import MyDrawer from "../../../commons/DrawerComponent";
i18next.addResourceBundle("en", "CATASTRO", en);
i18next.addResourceBundle("es", "CATASTRO", es);

const CatastroForm = () => {
  const { userHeader, setUserHeader, token, setToken, roles } =
    useContext(AppContext);
  const TOKEN =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoie1wiYXV0aFVzZXJVdWlkXCI6XCJkMDNjN2FjOS1iN2IyLTRmZTAtODM0My0zNmYwOTAwMTM2MTNcIixcImVtYWlsXCI6XCJnb256YWxvLmJ1ZW5vQGFnZm9yZXN0LmNvbVwiLFwicm9sZXNcIjpbe1wibmFtZVwiOlwiUk9MRV9BRE1JTlwifSx7XCJuYW1lXCI6XCJST0xFX0NPTUVSQ0lBTFNfRlVMTF9BTkFMWVNJU1wifSx7XCJuYW1lXCI6XCJST0xFX0NPTUVSQ0lBTFNfRlVMTF9BTkFMWVNJU19ET1dOTE9BRF9SRVBPUlRfQUdGT1JFU1RcIn0se1wibmFtZVwiOlwiUk9MRV9DT01FUkNJQUxTX0ZVTExfQU5BTFlTSVNfRE9XTkxPQURfUkVQT1JUX0FHRk9SRVNUXCJ9LHtcIm5hbWVcIjpcIlJPTEVfQ09NRVJDSUFMU19GVUxMX0FOQUxZU0lTX0RPV05MT0FEX1JFUE9SVF9MQUJPUkVcIn0se1wibmFtZVwiOlwiUk9MRV9DT01FUkNJQUxTX0ZVTExfQU5BTFlTSVNfRE9XTkxPQURfUkVQT1JUX0xBQk9SRVwifSx7XCJuYW1lXCI6XCJST0xFX0NPTUVSQ0lBTFNfRlVMTF9BTkFMWVNJU19ET1dOTE9BRF9SRVBPUlRfQlVSRUFVXCJ9LHtcIm5hbWVcIjpcIlJPTEVfQ09NRVJDSUFMU19GVUxMX0FOQUxZU0lTX0RPV05MT0FEX1JFUE9SVF9CVVJFQVVcIn1dfSIsImlhdCI6MTcwMTE5MjE1OCwianRpIjoiN2ZmYTI5NTYtYjE5ZC00YmE1LTliMmYtMjEyNWYxNDRmZDdiIiwibmJmIjoxNzAxMTkyMTU5fQ.J-ytGICXVU7qASLUbrg7H8wPEPPbr63RjPc6RUL2xg4";
  const { t } = useTranslation("CATASTRO");
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [longitud, setLongitud] = useState(false);
  const [latitud, setLatitud] = useState(false);

  const [agroServiceGeojson, setAgroServiceGeojson] = useState(false);
  const [fincas, setFincas] = useState(null);
  const [fincaGeometria, setFincaGeometria] = useState(false);
  const [finca, setFinca] = useState(false);
  const [polygonDraw, setPolygonDraw] = useState(null);
  const [isLateralPanelOpen, setIsLateralPanelOpen] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(false);
  const [drawEnabled, setDrawEnabled] = useState(false);

  const [lateralPdfVisible,setLateralPdfVisible] = useState(false);
  const [isCreateFincaModalOpen, setIsCreateFincaModalOpen] = useState(false);
  const [isUpdateFincaModalOpen, setIsUpdateFincaModalOpen] = useState(false);
  const start_empty_value = {
    name: "",
    crop_type: "",
    crop_variety: "",
    address: "",
    municipe: "",
    owner: "",
    notes: "",
  };
  const [fincaToModify, setFincaToModify] = useState(start_empty_value);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
  const mapHeightFullSize = "100vh ";
  const type_of_pdfs = [
    {"key":"attachments_heterogeneity" , "label":"Hetereogeneidad"},
    {"key":"attachments_variable_subscriber",  "label":"Abonado variable"} ,
    {"key":"attachments_biweekly" , "label":"Quincenal"} 
  ]
  // const mapHeightFullSize = "50vh";
  useEffect(() => {
    if (!checkIsTokenOk(token)) {
      Notiflix.Notify.warning("La sesión ha caducado");
      setToken("");
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      navigate("/login");
      return;
    }
  }, [token]);

  function redondearNumeros(arrayNumeros) {
    // Verificar si el primer elemento del array es un número flotante
    if (!Number.isInteger(arrayNumeros[0])) {
      return arrayNumeros.map((numero) => parseFloat(numero.toFixed(2)));
    } else {
      // Si el primer número no es flotante, devuelve el array original
      return arrayNumeros;
    }
  }

  const loadAgroServiceData = async () => {
    Notiflix.Loading.circle();
    let data = await get_agroservice_features(userHeader, TOKEN);
    console.log("Agro service features", data);
    if (data) {
      data = data.filter((e) => {
        return e["geometry"] != null;
      });
      setAgroServiceGeojson(data);
    }

    const f = await get_agroservice_list(userHeader, TOKEN);
    if (f) {
      const fin = f["elements"].map((e) => {
        e["label"] = e["name"];
        return e;
      });
      console.log("Guardando fincas", fin);
      setFincas(fin);
      setFinca(false);
      setFincaGeometria(false);
      setFincaToModify(start_empty_value);
    } else {
      Notiflix.Notify.failure("Error obteniendo los datos");
    }

    Notiflix.Loading.remove();
  };

  const handleUpdateFincaModal = async (event) => {
    console.log("handleNewFincaModal", polygonDraw, finca);
    Notiflix.Loading.circle();
    delete finca.attachments_biweekly;
    delete finca.attachments_heterogeneity;
    delete finca.attachments_variable_subscriber;
    delete finca.label;
    const id = finca.id;
    //delete finca.id;

    let response = await update_agroservice(finca, id, userHeader, TOKEN);
    Notiflix.Loading.remove();
    if (response != false) {
      //setFincaToModify(start_empty_value);
      setIsUpdateFincaModalOpen(false);
      loadAgroServiceData();
      Notiflix.Notify.info("Finca actualizada correctamente");
    } else {
      Notiflix.Notify.failure(
        "Problema creando la finca, inténtelo de nuevo más tarde"
      );
    }
  };
  const handleNewFincaModal = async (event) => {
    console.log("handleNewFincaModal", polygonDraw, fincaToModify);
    Notiflix.Loading.circle();
    fincaToModify["geometry"] = polygonDraw;
    let response = await create_agroservice(fincaToModify, userHeader, TOKEN);
    Notiflix.Loading.remove();
    if (response != false) {
      setFincaToModify(start_empty_value);
      setIsCreateFincaModalOpen(false);
      loadAgroServiceData();
      Notiflix.Notify.info("Finca creada correctamente");
    } else {
      Notiflix.Notify.failure(
        "Problema creando la finca, inténtelo de nuevo más tarde"
      );
    }
  };
  const handleCloseFincaModal = (event) => {
    console.log("handleCloseNewFincaModal", event);
    setFincaToModify({ ...start_empty_value });
    setFinca({ ...start_empty_value });
    setIsCreateFincaModalOpen(false);
    setIsUpdateFincaModalOpen(false);
  };
  const drawGeometry = (event) => {
    console.log("event draw end", event);
    if (
      event.detail.geometry != undefined &&
      event.detail.geometry != null &&
      event.detail.geometry.geometry != undefined &&
      event.detail.geometry.geometry.type === "Polygon"
    ) {
      setPolygonDraw(event.detail.geometry.geometry);
      setIsCreateFincaModalOpen(true);
      setIsLateralPanelOpen(false);
      setDrawEnabled(false);
    }
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (!checkIsTokenOk(token)) {
      Notiflix.Notify.warning("La sesión ha caducado");
      setToken("");
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      navigate("/login");
      return;
    }
    setTimeout(loadAgroServiceData, 250);
    document.addEventListener("drawGeometry", drawGeometry);
  }, []);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return (
    <Box
      sx={{
        //maxWidth: "var(--max-article-width)",
        width: "100%",
        height: "100%",
      }}
    >
     
      {/* <!-- Creación --> */}
      <GenericTextModalConfirmationWithButton
        buttonAction={handleNewFincaModal}
        endAction={handleCloseFincaModal}
        // InitialAction={handleCloseNewFincaModal}
        isModalOpenExternal={isCreateFincaModalOpen}
        Title={"Nueva finca"}
        BodyComponent={
          <FincaModal data={fincaToModify} setData={setFincaToModify} />
        }
        ConfirmText="Guardar"
        CancelText="Cancelar"
        _maxWidth="500px"
      />
      {/* <!-- Actualización --> */}
      <GenericTextModalConfirmationWithButton
        buttonAction={handleUpdateFincaModal}
        endAction={handleCloseFincaModal}
        // InitialAction={handleCloseNewFincaModal}
        isModalOpenExternal={isUpdateFincaModalOpen}
        Title={
          finca["name"] ? "Editando finca : " + finca["name"] : "Editando finca"
        }
        BodyComponent={<FincaModal data={finca} setData={setFinca} />}
        ConfirmText="Actualizar"
        CancelText="Cancelar"
        _maxWidth="500px"
      />
      <Paper className="catastro_form_paper_box">
        <MyDrawer
          isOpen={isLateralPanelOpen}
          setIsOpen={setIsLateralPanelOpen}
          elements={
            <>
              <div
                style={{
                  margin: "1rem",
                  backgroundColor: "transparent",
                  width: "100%",
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ height: "1rem", width: "100%" }}></div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    
                  }}
                >
                  <Tooltip title={t("catastro_label_location_search_box")}>
                    {fincas != null && (
                      <Autocomplete
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "0.5rem",
                          backgroundColor: "#ffffff",
                          margin:"0 1rem"
                        }}
                        disablePortal
                        options={fincas}
                        value={finca["name"] ? finca["name"] : ""}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "max(100%, min(500px, 80vw))",
                            },
                          },
                        }}
                        // defaultValue={undefined}
                        filterOptions={(options, state) => {
                          if (state.inputValue.length > 1 && options) {
                            return options.filter((item) =>
                              String(item.name)
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toLocaleLowerCase("es-ES")
                                .includes(
                                  state.inputValue
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                                    .toLocaleLowerCase("es-ES")
                                )
                            );
                          }
                          return options;
                        }}
                        onChange={(event, newValue) => {
                          console.log(
                            "Seleccionando finca : ",
                            newValue,
                            agroServiceGeojson
                          );
                          if (newValue && agroServiceGeojson) {
                            const geojson = Array.from(
                              agroServiceGeojson.filter(
                                (feature) =>
                                  feature["properties"]["id"] == newValue["id"]
                              )
                            );
                            setFincaGeometria(geojson);
                            setFinca(newValue);
                            //setIsLateralPanelOpen(false)
                          } else {
                            setFincaGeometria(false);
                            setFinca("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t(
                              "catastro_label_location_search_box"
                            )}
                            {...params}
                            // label={t("catastro_label_location_search_box")}
                          />
                        )}
                      />
                    )}
                  </Tooltip>
                  <Tooltip title={"Añadir una finca"}>
                    <IconButton
                      style={{
                        width: "auto",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "15%",
                        margin:"0 1rem"
                      }}
                      onClick={()=>{
                        setDrawEnabled(true);
                        setIsLateralPanelOpen(false);
                      }}
                    >
                      <AddCircleIcon style={{ color: "0000ff" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Divider variant="middle" style={{ marginTop: "1rem" }} />

              <Paper
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  marginTop: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  backgroundColor: "transparent",
                }}
              >
                {fincas &&
                  fincas.map((f) => {
                    return (
                      <Button
                        style={{ padding: "0.5rem", margin: "1rem 2.5rem" }}
                        sx={{
                          opacity: "0.9",
                          backgroundColor:
                            finca && f["id"] === finca["id"]
                              ? "#89e186"
                              : "white", // Cambia el color de fondo en hover
                          border: "1px solid darkgrey", // Establece el borde
                          boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)", // Añade sombra
                          ":hover": {
                            backgroundColor:
                              finca && f["id"] == finca["id"]
                                ? "#00ff00"
                                : "lightgray",
                            boxShadow: "0px 5px 8px rgba(0, 0, 0, 0.3)", // Aumenta la sombra en hover
                          },
                        }}
                        onClick={(evt) => {
                          const newValue = f;
                          if (newValue && agroServiceGeojson && newValue != finca) {
                            const geojson = Array.from(
                              agroServiceGeojson.filter(
                                (feature) =>
                                  feature["properties"]["id"] == newValue["id"]
                              )
                            );
                            setFincaGeometria(geojson);
                            setFinca(newValue);
                          } else {
                            setFincaGeometria(false);
                            setFinca(false);
                          }
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                color: "#212427",
                                textAlign: "left",
                                width: "90%",
                                paddingLeft: "1.5rem",
                                paddingRight:"0.5rem",
                                cursor: "pointer",
                              }}
                            >
                              <label style={{ fontSize: "1.5rem" }}>
                                {f["name"] && f["name"] != ""
                                  ? f["name"]
                                  : "No hay datos"}
                              </label>
                              <br />
                              <label style={{ fontSize: "1rem" }}>
                                {" "}
                                {f["crop_type"] && f["crop_type"] != ""
                                  ? f["crop_type"]
                                  : "No hay datos"}
                              </label>
                              <br />
                              <label style={{ fontSize: "1rem" }}>
                                {" "}
                                {f["crop_variety"] && f["crop_variety"] != ""
                                  ? f["crop_variety"]
                                  : "No hay datos"}
                              </label>
                            </div>
                          </div>
                          {f && f["id"] && lateralPdfVisible && lateralPdfVisible["id"] && lateralPdfVisible["id"] == f["id"] && (
  <div style={{paddingBlock: "1.5rem", zIndex:"9999"}} className="fade-in" onClick={(e)=>e.stopPropagation()}>
    {lateralPdfVisible["data"] ? type_of_pdfs.map(type_of_pdf => {
      const PDFS = lateralPdfVisible["data"];
      console.log("PDFS[type_of_pdf['key']]", PDFS[type_of_pdf['key']]);
      return  PDFS[type_of_pdf['key']] && (
        <Autocomplete
          style={{ width: "100%", height: "100%", marginTop: "1rem" }}
          
          options={PDFS[type_of_pdf['key']]}
          componentsProps={{
            paper: {
              sx: {
                width: "max(100%, min(500px, 80vw))",
              },
            },
          }}
          filterOptions={(options, state) => {
            if (state.inputValue.length > 1 && options) {
              return options.filter((item) =>
                String(item.name)
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLocaleLowerCase("es-ES")
                  .includes(
                    state.inputValue
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLocaleLowerCase("es-ES")
                  )
              );
            }
            return options;
          }}
          onChange={(event, newValue) => {
            console.log("Seleccionando finca : ", newValue);
            if(newValue && "url" in newValue) window.open(newValue["url"], "_blank");
          }}
          renderInput={(params) => (
            <TextField
              placeholder={"Busca tu PDF aquí"}
              {...params}
              label={"Reportes de " + type_of_pdf["label"]}
            />
          )}
        />
      );
    }) : ""}
  </div>
)}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "right",
                            }}
                          >
                            <Tooltip title={"Acceder a los informes de la finca"}>
                              <AssignmentIcon  style={{
                                  backgroundColor: f && f["id"] && lateralPdfVisible && lateralPdfVisible["id"] && lateralPdfVisible["id"] == f["id"]? "darkBlue": "blue",
                                  borderRadius: "0.3rem",
                                  color: "#00ff00",
                                  padding: "0.4rem",
                                  width: "3rem",
                                  height: "1rem",
                                  marginRight: "0.5rem",
                                  
                                }}
                                onClick={async(e)=>{
                                  e.stopPropagation();
                                  if (f && f["id"] && lateralPdfVisible && lateralPdfVisible["id"] && lateralPdfVisible["id"] == f["id"]){
                                    setLateralPdfVisible(false)
                                  }else{
                                    Notiflix.Loading.circle()
                                    //setFinca(f);
                                    const PDFS = await get_pdfs(f["id"], userHeader, TOKEN);
  
                                    // Ajustando nombres con TS
                                    if(PDFS != false && PDFS != undefined){
                                      for(let type_of_pdf of type_of_pdfs){
                                        PDFS[type_of_pdf['key']].map(e=>{
                                          let time = "";
                                          console.log("Procesando type_of_pdf y elemento:", type_of_pdf, e);
                                          try{
                                            const timestamp = e["name"].replace("/form_agroservice/", "").split("_")[0]/10
                                            const d = new Date(timestamp); // 1549312452
                                            console.log("Time timestamp of pdf", timestamp)
                                  
                                            time= new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(d);
                                            console.log("Time value of pdf", time)
                                            e["label"] =   e["name"].replace("/form_agroservice/", "").split("_")[1]
                                          }catch(error){
                                            console.log("Name with no time", e["namer"])
                                            try{
                                              e["label"] = e["name"].replace("/form_agroservice/", "").split("_")[1]
                                            }catch(err2){
                                              e["label"] = e["name"].replace("/form_agroservice/", "").split("_")[0]
                                            }
                                          }
                                          console.log("Devolviendo e", e)
                                          //e["label"] = time + "-"+ e["name"].replace("/form_agroservice/", "").split("_")[1]
                                          return e;
                                        });
                                      }
                                    }
                                    setLateralPdfVisible({"id":f["id"], "data":PDFS})
                                    Notiflix.Loading.remove()
                                  }
                                  

                              }}/>
                            </Tooltip>
                            <Tooltip title={"Editar finca"}>
                              <EditIcon
                                style={{
                                  backgroundColor: "blue",
                                  borderRadius: "0.3rem",
                                  color: "#00ff00",
                                  padding: "0.4rem",
                                  width: "3rem",
                                  height: "1rem",
                                  marginRight: "0.5rem",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFinca(f);
                                  setIsUpdateFincaModalOpen(true);
                                  setIsLateralPanelOpen(false);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={"Borrar finca"}>
                              <DeleteIcon
                                style={{
                                  backgroundColor: "blue",
                                  borderRadius: "0.3rem",
                                  color: "#00ff00",
                                  padding: "0.4rem",
                                  width: "3rem",
                                  height: "1rem",
                                }}
                                onClick={async (e) => {
                                  e.stopPropagation();

                                  Notiflix.Confirm.show(
                                    "¿Está seguro de borrar la finca?",
                                    "Si acepta, los datos de la finca " +
                                      f["name"] +
                                      " se borrarán permanentemente. <br/>¿Está de acuerdo?",
                                    "Sí",
                                    "No",
                                    async () => {
                                      Notiflix.Loading.circle();
                                      setFinca(false);
                                      await delete_agroservice(
                                        f["id"],
                                        userHeader,
                                        TOKEN
                                      );
                                      Notiflix.Loading.remove();
                                      loadAgroServiceData();
                                    },
                                    () => {
                                      console.log("Cancelando borrado");
                                    },
                                    { titleColor: "black" }
                                  );
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </Button>
                    );
                  })}
              </Paper>

              {/* {fincaGeometria != false && fincaGeometria != null &&    <>
            <Divider variant="middle" style={{marginTop:"1rem"}} />
          <label style={{fontSize:"2rem", fontWeight:"600"}}>Finca {finca["name"]}</label>
          <div  style={{marginTop:"1rem"}}>
           
          </div>
          </>      
          } */}
            </>
          }
        />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            gap: "0.5rem",
          }}
        >
          <div style={{ flex: "4 1 400px" }}>
            {agroServiceGeojson || fincaGeometria ? (
              <OpenLayersFullAnalysisMap
                isGetCoordinatesOnClickEnabled={false}
                mustShowOnClickFeature={true}
                isGeolocationEnabled={true}
                //isDrawEnabled={true}
                isDrawEnabledOnStartUp={drawEnabled}
                geojsons={JSON.stringify(
                  fincaGeometria ? fincaGeometria : agroServiceGeojson
                )}
                style={{
                  height: mapHeightFullSize,
                  width: "100%",
                }}
              />
            ) : (
              <OpenLayersMap
                style={{
                  height: mapHeightFullSize,
                  width: "100%",
                }}
              />
            )}
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default CatastroForm;
