
import LayerClass, { legendBoxes, hexToRgb } from "./LayerCommons";
import { useState } from "react";
import { renderToString } from 'react-dom/server'
import { Paper, TextField } from '@mui/material';
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput"
import "./LayerPublicAmiantic.css"
const legend = () => legendBoxes([
    { color: "#118ab2", text: "Abierto" },
    { color: "#ef476f", text: "Pendiente" },
    { color: "#06d6a0", text: "Cerrado" }
  ]);
const getBackground = (feature) => {
    const colors = {
      "PENDIENTE" : hexToRgb("#ef476f"),
      "ABIERTO" : hexToRgb("#118ab2"),
      "CERRADO" : hexToRgb("#06d6a0"),
    }
    //colors: ['#fdae61','#1a9641','#a6d96a','#d7191c' ], //['#d7191c', '#fdae61', '#a6d96a','#1a9641' ], // Colores para las categorías
    //           alta      BAJA      media  muy alta       

    let defaultColor = hexToRgb("#0000FF");
   // console.log("feature color ", feature)
    if (feature && "values_" in feature &&
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "status" in feature.values_.features[0].values_
    ) {
        const priority = feature.values_.features[0].values_["status"]
        //console.log("feature color values_", priority)

        if(Object.keys(colors).includes(priority) ){
          //console.log("feature color EXITO", colors[priority])
          return colors[priority]
        }else{
       //   console.log("feature color NO EXITO",priority,  colors[priority], feature.values_.features[0].values_)
          return defaultColor
        }
    }
  //  console.log("feature color NO EXITO VALUES", feature)
    return defaultColor;
  };



var info_field = (
    label,
    _data,
    fixed_decimals = false,
    is_date=false,
    backgroundColor = false,
    suffix = "",
  ) => {
      
   // console.log(_data);
    if (fixed_decimals && _data !== undefined && _data !== null) {
      //_data = _data.toFixed(fixed_decimals).toString();
      _data = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,

      }).format(_data).toString();
    }
    if(is_date  && _data !== undefined && _data !== null){
      _data = new Date(_data).toISOString().slice(0, 10);
    }
    return (

      <LayerPublicAmianticTextInput
        style={{
          width: "90%",
          paddingLeft:"5%",
         // marginTop: "0.8rem",
          backgroundColor: backgroundColor,
        }}
       // sx={{style:{width:"100%"}}}
       // size="small"
        label={label}
        value={"" + _data + suffix}
        //readonly
      />
    );
  };
  

  const getTooltip = (feature)=>{
    if (
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "status" in feature.values_.features[0].values_
    ) {
      return "Estado actual : "+feature.values_.features[0].values_["status"]
    }
  };
const amianticPublicModal = (feature)=>{
    console.log("amianticPublicModal feature", feature)
    if (
      feature &&
      "values_" in feature &&
      "status" in feature["values_"] 
    ){
          
      const data = feature.values_;
      let data_processed = JSON.parse(JSON.stringify(data));
      const replacements = {
        "Provincia":"Provincia",
        "Municipio":"Municipio",
        "reference":"Ref. Catastral",
        "currentUse":"Uso actual del edificio",
        "beginning":"Fecha de construcción",
        "age":"Antigüedad",
        "a_total": "Área",
        "Link_catastro":"Catastro online",
        "Link_image":"Imagen del inmueble",
        "area_amianto":"Superficie de amianto",
        "amianto_percentage":"Porcentaje de ocupación de amianto",
        "priority":"Prioridad de retirada",
        "date_withdrawal":"Calendario de retirada",
        "withdrawal_cost":"Coste de retirada",
        "labour_cost":"Coste de la mano de obra",
        "new_cover_cost":"Coste de nueva cubierta",
        "building_license_cost":"Coste de licencia de obra",
        "new_basic_cover_cost":"Coste de nueva cubierta básica",
        "basic_building_license_cost":"Coste de licencia de obra básica",
        "status":"Estado",
        "made_by":"Análisis realizado por"
       //"Superficie de amianto":
      }
      const links = [
        "Link_catastro",
        "Link_image"
      ]
      const units = {
        "a_total": " m²",
        "area_amianto":" m²",
        "age":" años",
        "amianto_percentage":" %",
        "withdrawal_cost": " €",
        "labour_cost": " €",
        "new_cover_cost": " €",
        "building_license_cost": " €",
        "new_basic_cover_cost": " €",
        "basic_building_license_cost": " €",
      }
      const is_float = [
        "a_total", 
        "amianto_percentage",
        "area_amianto",
        "withdrawal_cost",
        "labour_cost",
        "new_cover_cost",
        "building_license_cost",
        "new_basic_cover_cost",
        "basic_building_license_cost"]
      const is_date = []
      for(const key of Object.keys(data)){
        if(!Object.keys(replacements).includes(key)){
          delete data_processed[key];
        }
      }
      // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
       const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
      let catastroUrl = "";
      let catastroImageUrl = "";
      if(Object.keys(data).includes("Link_catastro")){
        catastroUrl= data["Link_catastro"]
      }else if(Object.keys(data).includes("reference")){
        const rc1 = data["reference"].substring(0, 7);
        const rc2 = data["reference"].substring(7, 14);
        catastroUrl = uri + rc1 + "&rc2=" + rc2;

        data_processed["Link_catastro"] = catastroUrl;

      }
      if(Object.keys(data).includes("reference")){
        catastroImageUrl= "https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"];
      }
      //   properties.put("urlIframe", catastroUrl);
      // }
     
      
      return renderToString(<div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
        <div style={{display:"flex",  flexDirection:"column", flex:"1 1 220px", maxHeight:"70vh", overflowY:"auto", paddingLeft:"1rem", paddingTop:"1rem"}}>
          {Object.keys(replacements).map(key=>{
            let value = data_processed[key];
            let suffix = "";
            if(Object.keys(units).includes(key)){
              suffix=units[key];
            }
            return info_field(replacements[key], value , is_float.includes(key), is_date.includes(key), false, suffix);
          })}
        </div>
        {catastroImageUrl != "" && <div  style={{display:"flex", flex:"3 1 320px",  maxHeight:"70vh"}}>
         <a href={catastroImageUrl} target='blank'> <img style={{width: "100%", borderRadius: "10px",height:"100%",   objectFit:"cover"}}  src={catastroImageUrl}/></a>
        </div>}
      </div>)
      //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
    }
    return false;
  }

export const LAYER_PUBLIC_AMIANTIC = new LayerClass(
    "Estado de la inspección",
    legend,
    getBackground,
    getTooltip,
    amianticPublicModal
  );