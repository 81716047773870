import axios from "axios";
import jwt_decode from "jwt-decode";

export default  function get_geojson(municipio, provincia, ine_code = "") {
  const URL_BASE =
    "https://nominatim.openstreetmap.org/search.php?format=jsonv2&countrycodes=es&polygon_geojson=1&limit=10";
  //&q=alfarnate
  if (municipio.includes(", ")) {
    if (municipio.includes("/")) {
      municipio = municipio.split("/")[0];
    }
    let municipio_split = municipio.split(", ");
    municipio = municipio_split[1] + " " + municipio_split[0];
  }
  let url = URL_BASE + "&q=" +  municipio ;
  let config = {
    method            : "GET",
    url               : url,
    rejectUnauthorized: false,
    headers           : {
      accept: "*/*",
    },
  };
  return  axios({ ...config, headers: { ...config.headers } });
  
}


const getValueIfExist = (
  data,
  key,
  txt_not_exist,
  fixe_float = false,
  fix_percentage = false
) => {
  if (Object.keys(data).includes(key) && data[key]) {
    return fixe_float
      ? fix_percentage
        ? (parseFloat(data[key]) * 100).toFixed(2)
        : parseFloat(data[key]).toFixed(2)
      : data[key];
  } else {
    return txt_not_exist;
  }
};

export const get_report_situacion = async (ineCode,userHeader,template_id,  token) => {
    let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/";
    let config = {
      method        : "get",
      maxBodyLength : Infinity,
      url           : URL_BASE + "createPDF_V2/" + ineCode + "/" +  template_id,
      headers: {
        us                            : userHeader,
        Authorization                 : token,
        "Content-Type"                : "*",
        "Access-Control-Allow-Headers": "*",
        Accept                        : "*/*",
      },
    };
    return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
};
export async function get_graph_data(ine_code, graph_type,userHeader, token){
  let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials/";
  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + "get_graph_data/" + graph_type + "/" + ine_code,
    headers: {
      us                            : userHeader,
      Authorization                 : token,
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}
export async function get_graph_data_from_uuid(uuid, graph_type){
  let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/microservices/comercials_public/get_public_graph_data/";
  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + graph_type + "/" + uuid,
    headers: {
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}
export async function loginService(email, password) {
  let URL_BASE = process.env.REACT_APP_API_URL + "amiantic/v1.0/api/auth/login";

  let config = {
    method: "post",
    data: { email, password },
    url: URL_BASE,
    headers: {
      //us: userHeader,
      // "Content-Type": "*",
      //"Access-Control-Allow-Headers": "*",
      Accept: "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return false;
    });
}

export async function get_offer_status(ine_code, userHeader, token) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + "offer_status/" + ine_code,
    headers       : {
      us                            : userHeader,
      Authorization                 : token,
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}

export async function get_advanced_geometry_from_uuid(uuid) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials_public/get_public_map/advanced/";

  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE  + uuid,
    headers       : {
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}
export async function get_advanced_geometry_from_ine(ine_code, userHeader, token) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + "getGeometry/advanced/" + ine_code,
    headers       : {
      us                            : userHeader,
      Authorization                 : token,
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}
export async function get_basic_geometry_from_ine(ine_code, userHeader, token) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + "getGeometry/basics/" + ine_code,
    headers       : {
      us                            : userHeader,
      Authorization                 : token,
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}
export async function get_full_geometry_from_ine(ine_code, userHeader, token) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + "getGeometry/advanced/" + ine_code,
    headers       : {
      us                            : userHeader,
      Authorization                 : token,
      "Content-Type"                : "*",
      "Access-Control-Allow-Headers": "*",
      Accept                        : "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}
export async function set_offer_status(ine_code, userHeader, token, new_status) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let status = 0;
  if(new_status === true){
    status = 1;
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    data:{},
    url: URL_BASE + "set_offer_status/" + ine_code + "/"+status,
    headers: {
      us: userHeader,
      Authorization: token,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });
}

export async function get_municipio_pricing_info(ine_code, userHeader, token) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method        : "get",
    maxBodyLength : Infinity,
    url           : URL_BASE + "get_municipe_info/" + ine_code,
    headers       : {
      us: userHeader,
      Authorization: token,
      "Content-Type": "*",
      "Access-Control-Allow-Headers": "*",
      Accept: "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return {
        ...data,
        poblacion: getValueIfExist(
          data,
          "poblation",
          "Valor no disponible",
          false
        ),
        superficie: getValueIfExist(data, "area", "Valor no disponible", true),
        densidad_poblacion: getValueIfExist(
          data,
          "poblation_density",
          "Valor no disponible",
          true
        ),
        ref_catastrales: getValueIfExist(
          data,
          "ref_catastrales",
          "Valor no disponible",
          false
        ),
        area_construida: getValueIfExist(
          data,
          "urban_area",
          "Valor no disponible",
          true
        ),
        area_industrial: getValueIfExist(
          data,
          "indus_area",
          "Valor no disponible",
          true
        ),
        autonomy: getValueIfExist(
          data,
          "autonomy",
          "Valor no disponible",
          false
        ),
        buildings_rate: getValueIfExist(
          data,
          "buildings_rate",
          "Valor no disponible",
          true
        ),
        cost: getValueIfExist(data, "cost", "Valor no disponible", true),
        province: getValueIfExist(
          data,
          "province",
          "Valor no disponible",
          false
        ),
        municipe: getValueIfExist(
          data,
          "municipe",
          "Valor no disponible",
          false
        ),
        cost_ref_catastral: getValueIfExist(
          data,
          "cost_ref_catastral",
          "Valor no disponible",
          true
        ),
        price_total: getValueIfExist(
          data,
          "price_total",
          "Valor no disponible",
          true
        ),
        cost_down_25: getValueIfExist(
          data,
          "down_25",
          "Valor no disponible",
          true
        ),
        cost_total_agforest: getValueIfExist(
          data,
          "cost_total_agforest",
          "Valor no disponible",
          true
        ),
        area_industrial_percentage:
          "indus_area" in data &&
          "urban_area" in data &&
          data["urban_area"] &&
          data["indus_area"]
            ? ((data["indus_area"] * 100) / data["urban_area"]).toFixed(2)
            : "Valor no disponible",
      };
    })
    .catch((error) => {
     // console.log(error);
      return false;
    });

  // return {
  //   poblacion: 21000,
  //   superficie: 10000,
  //   densidad_poblacion: 5132.1231231,
  //   ref_catastrales: 32123,
  //   area_construida: 1234,
  //   area_industrial: 1234,
  //   area_industrial_percentage: (1234 * 100) / 10000,
  // };
}

export function checkIsTokenOk(token) {
  let decoded = jwt_decode(token);
  let now = new Date();
  let exp_date = new Date(0); // The 0 there is the key, which sets the date to the epoch
  exp_date.setUTCSeconds(decoded["exp"]);
  return exp_date > now;
}

export async function get_municipio_basic_info(ine_code, userHeader, token) {
  let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: URL_BASE + "basic_analysis/" + ine_code,
    headers: {
      us: userHeader,
      Authorization: token,
      "Access-Control-Allow-Headers": "*",
      // Referer: "asbestos.ai",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return {
        ...data,
        poblacion: getValueIfExist(
          data,
          "poblation",
          "Valor no disponible",
          false
        ),
        superficie: getValueIfExist(data, "area", "Valor no disponible", true),
        densidad_poblacion: getValueIfExist(
          data,
          "poblation_density",
          "Valor no disponible",
          true
        ),
        ref_catastrales: getValueIfExist(
          data,
          "ref_catastrales",
          "Valor no disponible",
          false
        ),
        area_construida: getValueIfExist(
          data,
          "urban_area",
          "Valor no disponible",
          true
        ),
        area_industrial: getValueIfExist(
          data,
          "indus_area",
          "Valor no disponible",
          true
        ),
        autonomy: getValueIfExist(
          data,
          "autonomy",
          "Valor no disponible",
          false
        ),
        buildings_rate: getValueIfExist(
          data,
          "buildings_rate",
          "Valor no disponible",
          true
        ),
        cost: getValueIfExist(data, "cost", "Valor no disponible", true),
        province: getValueIfExist(
          data,
          "province",
          "Valor no disponible",
          false
        ),
        municipe: getValueIfExist(
          data,
          "municipe",
          "Valor no disponible",
          false
        ),
        cost_ref_catastral: getValueIfExist(
          data,
          "cost_ref_catastral",
          "Valor no disponible",
          true
        ),
        price_total: getValueIfExist(
          data,
          "price_total",
          "Valor no disponible",
          true
        ),
        cost_down_25: getValueIfExist(
          data,
          "down_25",
          "Valor no disponible",
          true
        ),
        cost_total_agforest: getValueIfExist(
          data,
          "cost_total_agforest",
          "Valor no disponible",
          true
        ),
        number_of_buildings_2002: getValueIfExist(
          data,
          "number_of_buildings_2002",
          "Valor no disponible"
        ),
        percentage_pre_ban_filter: getValueIfExist(
          data,
          "percentage_pre_ban_filter",
          "Valor no disponible",
          true,
          true
        ),
        built_area: getValueIfExist(
          data,
          "built_area",
          "Valor no disponible",
          true
        ),
        mean_antique: getValueIfExist(
          data,
          "mean_antique",
          "Valor no disponible",
          true
        ),
        area_industrial_percentage:
          "indus_area" in data &&
          "urban_area" in data &&
          data["urban_area"] &&
          data["indus_area"]
            ? ((data["indus_area"] * 100) / data["urban_area"]).toFixed(2)
            : "Valor no disponible",
      };
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  // return {
  //   poblacion: 21000,
  //   superficie: 10000,
  //   densidad_poblacion: 5132.1231231,
  //   ref_catastrales: 32123,
  //   area_construida: 1234,
  //   area_industrial: 1234,
  //   area_industrial_percentage: (1234 * 100) / 10000,
  // };
}
