import React, { useState } from 'react';

import { ChevronLeft , ChevronRight} from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import "./DrawerComponent.css"
import {ClickAwayListener} from '@mui/base';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import GenericTextModalConfirmationWithButton from '../components/modals/GenericTextModalConfirmationWithButton/GenericTextModalConfirmationWithButton';
import InfoIcon from "@mui/icons-material/Info";
import Description_informe from './Description_informe';

function MyDrawer({elements=<></>, isOpen, setIsOpen}) {
  //const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {

    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const drawerWidth = 400
  const toggleDrawer = () => {
    if(isOpen){ // vamos a cerrarlo
      handleMenuClose()
    }
    setIsOpen(!isOpen);
  };
  
  const handleClickAway = ()=>{
    console.log("Handle click away", isOpen)
    if(isOpen){
      setIsOpen(false)
      handleMenuClose()

    }
  }

  const header_menu = ()=>{
    return  <div
    style={{
      display: "flex",
      // backgroundColor: "var(--color-third)",

      justifyContent: "flex-start",
      width: "100%",
      heigt:"1rem",
      backgroundColor:"white"
      // borderBottom: "2px solid rgb(var(--color-primary))",
    }}
  >
    <IconButton
      onClick={handleMenuClick}
      aria-label="Información"
      style={{ marginBottom: "10px", marginRight: "20px" }}
    >
      {anchorEl ? (
        <MenuOpenIcon
          style={{
            color: "rgb(var(--color-secondary))",
            backgroundColor: "rgb(var(--color-primary))",
            borderRadius: "50%",
            padding: "0.3rem",
          }}
        />
      ) : (
        <MenuIcon
          style={{
            color: "rgb(var(--color-primary))",
            padding: "0.3rem",
          }}
        />
      )}
    </IconButton>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <GenericTextModalConfirmationWithButton
          buttonAction={handleMenuClose}
          InitialAction={()=>{
            handleMenuClose();     setIsOpen(false); }}
          ModalButton={
            <>
              <InfoIcon style={{ marginRight: "1.2rem" }} />
              Información sobre los informes
            </>
          }
          Title={"Información"}
          BodyComponent={<Description_informe/>}
          ConfirmText="Ok"
          CancelText={false}
          _maxWidth={"500px"}
        />
      </MenuItem>
      <MenuItem>

            <div onClick={()=>    {
              setIsOpen(false);
              handleMenuClose();
              window.open('https://agforest.ai/contacto' , "_blank")}}>
              <InfoIcon style={{ marginRight: "1.2rem" }} />
              ¿Tienes alguna duda?
            </div>
      </MenuItem>
      {/* <MenuItem>
        <GenericTextModalConfirmationWithButton
          buttonAction={handleClose}
          InitialAction={handleClose}
          ModalButton={
            <>
              <Help style={{ marginRight: "1.2rem" }} />
              Ley 07/2022
            </>
          }
          Title={t("txt_description_title")}
          BodyComponent={
            <Paper style={{ padding: "0.5rem", width: "100%" }}>
              <label
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                {replaceWithLink(
                  t("txt_description"),
                  "07/2022",
                  "https://www.boe.es/buscar/act.php?id=BOE-A-2022-5809"
                )}
              </label>
            </Paper>
          }
          ConfirmText="Ok"
          CancelText={false}
        />
      </MenuItem> */}
    </Menu>
  </div>
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>

    <div>

      <div  style={{ position: 'absolute', top:"50vh", left: isOpen ? drawerWidth : 0 }} className='drawerButton'  >

      <IconButton id="DrawerButton" onClick={toggleDrawer}>
        {isOpen ? <ChevronLeft   style={{fontSize:"2rem"}}/> : <ChevronRight  style={{fontSize:"2rem"}} />}
      </IconButton>
      </div>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        style={{ width: drawerWidth }}
        sx={{
            width: drawerWidth,
           
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor:"#000000bb",
              boxSizing: 'border-box',
            //   backgroundColor:"var(--color-third)",
            },
          }}
      >
        {/* <IconButton onClick={toggleDrawer}>
          <ChevronLeft />
        </IconButton> */}
        {header_menu()}
        {elements}
       
      </Drawer>
    </div>
    </ClickAwayListener>

  );
}

export default MyDrawer;
