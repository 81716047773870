import LayerClass, { legendBoxes, hexToRgb, tooltipBeginning } from "./LayerCommons";
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput"
import { renderToString } from 'react-dom/server'
import "./LayerPublicAmiantic.css"
import { get_pdfs } from "../../services/form_service";
import { Autocomplete, TextField } from "@mui/material";
import Notiflix from "notiflix";
import "./LayerAgro.css"
const legend = () =>
  legendBoxes([
    // { color: "#00ff00", text: "Post 2002" },
    // { color: "#ff8000", text: "Pre 2002" },
  ]);
  var info_field = (
    label,
    _data,
    fixed_decimals = false,
    is_date=false,
    backgroundColor = false,
    suffix = "",
  ) => {
      
   // console.log(_data);
    if (fixed_decimals && _data !== undefined && _data !== null) {
      //_data = _data.toFixed(fixed_decimals).toString();
      _data = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,

      }).format(_data).toString();
    }
    if(is_date  && _data !== undefined && _data !== null){
      _data = new Date(_data).toISOString().slice(0, 10);
    }
    return (

      <LayerPublicAmianticTextInput
        style={{
          width: "90%",
          paddingLeft:"5%",
         // marginTop: "0.8rem",
          backgroundColor: backgroundColor,
        }}
       // sx={{style:{width:"100%"}}}
       // size="small"
        label={label}
        value={"" + _data + suffix}
        //readonly
      />
    );
  };
  
const background = (feature) => {
  const post2002Color = hexToRgb("#00ff00");
  const pre2002Color = hexToRgb("#ff8000");
  const defaultColor = hexToRgb("#0000FF");
  return defaultColor;
};

const agroModal = async (feature)=>{
  Notiflix.Loading.circle();
  console.log("agroModal feature", feature)
  if (
    feature &&
    "values_" in feature
  ){
    const data = feature.values_;
    console.log("Data", data)

    const userHeader = "gonzalo.bueno@agforest.es"
    const TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoie1wiYXV0aFVzZXJVdWlkXCI6XCJkMDNjN2FjOS1iN2IyLTRmZTAtODM0My0zNmYwOTAwMTM2MTNcIixcImVtYWlsXCI6XCJnb256YWxvLmJ1ZW5vQGFnZm9yZXN0LmNvbVwiLFwicm9sZXNcIjpbe1wibmFtZVwiOlwiUk9MRV9BRE1JTlwifSx7XCJuYW1lXCI6XCJST0xFX0NPTUVSQ0lBTFNfRlVMTF9BTkFMWVNJU1wifSx7XCJuYW1lXCI6XCJST0xFX0NPTUVSQ0lBTFNfRlVMTF9BTkFMWVNJU19ET1dOTE9BRF9SRVBPUlRfQUdGT1JFU1RcIn0se1wibmFtZVwiOlwiUk9MRV9DT01FUkNJQUxTX0ZVTExfQU5BTFlTSVNfRE9XTkxPQURfUkVQT1JUX0FHRk9SRVNUXCJ9LHtcIm5hbWVcIjpcIlJPTEVfQ09NRVJDSUFMU19GVUxMX0FOQUxZU0lTX0RPV05MT0FEX1JFUE9SVF9MQUJPUkVcIn0se1wibmFtZVwiOlwiUk9MRV9DT01FUkNJQUxTX0ZVTExfQU5BTFlTSVNfRE9XTkxPQURfUkVQT1JUX0xBQk9SRVwifSx7XCJuYW1lXCI6XCJST0xFX0NPTUVSQ0lBTFNfRlVMTF9BTkFMWVNJU19ET1dOTE9BRF9SRVBPUlRfQlVSRUFVXCJ9LHtcIm5hbWVcIjpcIlJPTEVfQ09NRVJDSUFMU19GVUxMX0FOQUxZU0lTX0RPV05MT0FEX1JFUE9SVF9CVVJFQVVcIn1dfSIsImlhdCI6MTcwMTE5MjE1OCwianRpIjoiN2ZmYTI5NTYtYjE5ZC00YmE1LTliMmYtMjEyNWYxNDRmZDdiIiwibmJmIjoxNzAxMTkyMTU5fQ.J-ytGICXVU7qASLUbrg7H8wPEPPbr63RjPc6RUL2xg4"
    const PDFS = await get_pdfs(data["id"], userHeader, TOKEN);
    let data_processed = JSON.parse(JSON.stringify(data));
    const replacements = {
      "name" : "Nombre",
      "crop_type" : "Tipo de cultivo",
      "crop_variety" : "Variedad",
      "address" : "Dirección",
      "municipe" : "Municipio",
      "owner" : "Propietario",
      "area" : "Area",
      "perimeter" : "Perímetro",
      "notes":"Notas"
     //"Superficie de amianto":
    }
    const links = [
     
    ]
    const units = {
      "area": " m²",
      "perimeter":" m²",
    }
    const is_float = [
      "area", 
      "perimeter",
    ]
    const is_date = []
    for(const key of Object.keys(data)){
      if(!Object.keys(replacements).includes(key)){
        delete data_processed[key];
      }
    }

    
    const type_of_pdfs = [
      {"key":"attachments_heterogeneity" , "label":"Hetereogeneidad"},
      {"key":"attachments_variable_subscriber",  "label":"Abonado variable"} ,
      {"key":"attachments_biweekly" , "label":"Quincenal"} 
    ]
    if(PDFS != false && PDFS != undefined){
      for(let type_of_pdf of type_of_pdfs){
        PDFS[type_of_pdf['key']].map(e=>{
          let time = "";
          console.log("Procesando type_of_pdf y elemento:", type_of_pdf, e);
          try{
            const timestamp = e["name"].replace("/form_agroservice/", "").split("_")[0]/10
            const d = new Date(timestamp); // 1549312452
            console.log("Time timestamp of pdf", timestamp)
  
            time= new Intl.DateTimeFormat('es-ES', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(d);
            console.log("Time value of pdf", time)
            e["label"] =   e["name"].replace("/form_agroservice/", "").split("_")[1]
          }catch(error){
            console.log("Name with no time", e["namer"])
            try{
              e["label"] = e["name"].replace("/form_agroservice/", "").split("_")[1]
            }catch(err2){
              e["label"] = e["name"].replace("/form_agroservice/", "").split("_")[0]
            }
          }
          console.log("Devolviendo e", e)
          //e["label"] = time + "-"+ e["name"].replace("/form_agroservice/", "").split("_")[1]
          return e;
        });
      }
    }
 



    Notiflix.Loading.remove();
    return <div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
      <div style={{display:"flex",  flexDirection:"column", flex:"1 1 220px", maxHeight:"70vh", overflowY:"auto", paddingLeft:"1rem", paddingTop:"1rem"}}>
        {Object.keys(replacements).map(key=>{
          let value = data_processed[key];
          console.log("Dentro del map", replacements[key], value, data_processed)

          let suffix = "";
          if(Object.keys(units).includes(key)){
            suffix=units[key];
          }
         return info_field(replacements[key], value , is_float.includes(key), is_date.includes(key), false, suffix);
        //return <label>{key} - {value}</label>
        })}
        {PDFS ? type_of_pdfs.map(type_of_pdf =>{
          // return PDFS[type_of_pdf['key']].map(pdf_list =>{
          //   console.log("Cargando autocompletado", pdf_list)
            
          //   return pdf_list.map(pdf_object=>{
          //     return <a href={pdf_object["url"]} target="_blank">Informe de {type_of_pdf["label"]}</a>
          //   })
          console.log("PDFS[type_of_pdf['key']]", PDFS[type_of_pdf['key']])
            return  PDFS[type_of_pdf['key']] && <Autocomplete
            style={{ width: "100%", height: "100%", marginTop:"1rem" }}
            disablePortal
            options={PDFS[type_of_pdf['key']]}
            componentsProps={{
              paper: {
                sx: {
                  width: "max(100%, min(500px, 80vw))",
                },
              },
            }}
            // defaultValue={undefined}
            filterOptions={(options, state) => {
              if (state.inputValue.length > 1 && options) {
                return options.filter((item) =>
                  String(item.name)
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLocaleLowerCase("es-ES")
                    .includes(
                      state.inputValue
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLocaleLowerCase("es-ES")
                    )
                );
              }
              return options;
            }}
            onChange={(event, newValue) => {
              console.log("Seleccionando finca : ", newValue)
              if(newValue && "url" in newValue) window.open(newValue["url"], "_blank")
              
            }}
            renderInput={(params) => (
              <TextField
                placeholder={"Busca tu PDF aquí"}
                {...params}
                label={"Descargue sus reportes de "+type_of_pdf["label"]}
              />
            )}
          />
          //})
        }):""
        }
      </div>
      {/* {catastroImageUrl != "" && <div  style={{display:"flex", flex:"3 1 320px",  maxHeight:"70vh"}}>
       <a href={catastroImageUrl} target='blank'> <img style={{width: "100%", borderRadius: "10px",height:"100%",   objectFit:"cover"}}  src={catastroImageUrl}/></a>
      </div>} */}
    </div>
    //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
  }
  return false;
}
const tooltipAgro = (feature)=>{
  let txt = "Información"
  let intro = "<br/>"
  if ("features" in feature.values_ && feature.values_.features.length >= 1 ){
    const element = feature.values_.features[0].values_;
    
    if("name"         in element) txt =                     "<strong>"+ element["name"] + "</strong>"
    // if("crop_type"    in element) txt += intro + "Tipo de cultivo : "           + element["crop_type"]
    // if("crop_variety" in element) txt += intro + "Variedad del cultivo : "      + element["crop_variety"]
    // if("address"      in element) txt += intro + "Dirección : "                 + element["address"]
    // if("municipe"     in element) txt += intro + "Municipio : "                 + element["municipe"]
    // if("owner"        in element) txt += intro + "Propietario : "               + element["owner"]
    // if("area"         in element) txt += intro + "Area : "                      + new Intl.NumberFormat("es-ES", {maximumFractionDigits: 2, minimumFractionDigits: 2,}).format( element["area"]).toString();
    // if("perimeter"    in element) txt += intro + "Perímetro : "                 + new Intl.NumberFormat("es-ES", {maximumFractionDigits: 2, minimumFractionDigits: 2,}).format( element["perimeter"]).toString();
    
    txt += intro + '<table id="tabla-moderna">'
    if("crop_type"    in element) txt += "<tr><th>" + "Tipo de cultivo" + "</th><th>" + element["crop_type"] + "</th></tr>"
    if("crop_variety" in element) txt += "<tr><th>" + "Variedad del cultivo"  + "</th><th>" + element["crop_variety"] + "</th></tr>"
    if("address"      in element) txt += "<tr><th>" + "Dirección" + "</th><th>" + element["address"] + "</th></tr>"
    if("municipe"     in element) txt += "<tr><th>" + "Municipio" + "</th><th>" + element["municipe"] + "</th></tr>"
    if("owner"        in element) txt += "<tr><th>" + "Propietario" + "</th><th>" + element["owner"] + "</th></tr>"
    if("area"         in element) txt += "<tr><th>" + "Area"  + "</th><th>" + new Intl.NumberFormat("es-ES", {maximumFractionDigits: 2, minimumFractionDigits: 2,}).format( element["area"]).toString()+ " m²</th></tr>"
    if("perimeter"    in element) txt += "<tr><th>" + "Perímetro" + "</th><th>" +  new Intl.NumberFormat("es-ES", {maximumFractionDigits: 2, minimumFractionDigits: 2,}).format( element["perimeter"]).toString()+ " m²</th></tr>"
    txt += intro + '</table>'
  }

  return txt
}

export const LAYER_AGRO = new LayerClass(
  "Fincas registradas",
  legend,
  background,
  tooltipAgro,
  agroModal
);
