import "ol/ol.css";
import React, { useEffect, useRef, useState, createRef } from "react";
import {createRoot} from "react-dom/client"
import "./OpenLayersFullAnalysisMap.css";
import {LAYER_PRE_2002,LAYER_DATE_CONSTRUCTION, LAYER_SUSCEPTIBILITY, LAYER_CURRENT_USE,LAYER_PUBLIC_AMIANTIC, LAYER_TRANSPARENT, LAYER_AGRO } from "./components/Objects/Layers/LayerClass"
import GenericInfoMapModal from "./GenericInfoMapModal/GenericInfoMapModal";
import { Control } from "ol/control";
import { TextField } from "@mui/material";
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import { renderToString } from 'react-dom/server'
import { Tile as TileLayer} from "ol/layer";
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Draw from 'ol/interaction/Draw.js';
import DrawIcon from '@mui/icons-material/Draw';
import { Vector as VectorSource } from "ol/source";
import GeoJSON from 'ol/format/GeoJSON.js';
import RenderFeature, {toFeature} from 'ol/render/Feature';
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import Geolocation from 'ol/Geolocation.js';
import * as ReactDOM from 'react-dom';
import PlaceIcon from '@mui/icons-material/Place';
import { Point } from 'ol/geom';
import Notiflix from "notiflix"
import {
  baseLayerInit,
  calculeCenterOfFeatures,
  getSourceBaseMapFromName,
  getVectorLayerFromPolygonVectorSources,
  getVectorLayerFromPointVectorSourcesV2,
  handleMapClick,
  handleMapPointerMove,
  addMarkerToMap,
  initProjections,
  mapInit,
  overlayInit,
  readGeoJsons,
  scaleLineInit,
  tooltipInit,
  addGeolocation,
  updateLayerStyle,
} from "./commons/geoSpatialFunctions";

const OpenLayersMap = ({
  geojsons = "[]",
  style = { width: "100%", height: "100%", display: "flex" },
  strokeColor = "#000000",//"#0000FF",
  strokeHoverColor = "#0000FF",
  strokeWidth = 0.2,
  fillColor = "0, 0, 255",
  fillPolygonsOpacity = "0.85",
  fillPointsOpacity = "0.8",
  defaultSelectedLayer = "layer_agro",
  layers_data = false,
  getBgColorFromFeature = (feature)=>{return  "116, 86, 171"},
  mustShowOnClickFeature = true,
  isDrawEnabled = false,
  isDrawEnabledOnStartUp = false,
  assetsBasePath = false,
  isAdressSearchBarEnabled = true,
  isGetCoordinatesOnClickEnabled = false,
  isGeolocationEnabled = false,
}) => {
  
  const [selectedLayer, setSelectedLayer] = useState(defaultSelectedLayer);
  const [drawEnabled, setDrawEnabled] = useState(false)
  const GEOAPIFY_KEY = "4eb2e90e60de455bb2360adb3c89c15b";
  const geoapify_ref = createRef(null);
  

  
  var pointMarker = [false];
  let DEFAULT_CENTER = [-3.70256, 40.4165]; // default madrid
  let drawSource = new VectorSource({wrapX: false});
  let draw;
  let mustClean = false;
  const drawType = "Polygon"
  draw = new Draw({
    source: drawSource,
    type: drawType,
  });
   const [oldZoom, setOldZoom] = useState(false);
   const [oldCenter, setOldCenter] = useState(false);
   const [layerHasChanged, setLayerHasChanged] = useState(false);
   //let layerHasChanged = false;
  //let oldZoom = false;
  //let oldCenter = false;
  const DEFAULT_LAYERS_DATA = {
    // "pre2002":LAYER_PRE_2002,
    // "dateConstruction":LAYER_DATE_CONSTRUCTION,
    // "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
    // "currentUse" : LAYER_CURRENT_USE,
    "layer_agro":LAYER_AGRO,
    "transparent":LAYER_TRANSPARENT

  }

  const [geoPositionPoint, setGeoPositionPoint] = useState();
  const [geoAccuraccyGeometry, setGeoAccuraccyGeometry] = useState(null);
  layers_data = layers_data != false ? layers_data : DEFAULT_LAYERS_DATA;
  getBgColorFromFeature= (feature)=>{

    return layers_data[selectedLayer].colorBgFeatureFunction(feature)
  }
  
  const mapRef = useRef();
  const tooltipRef = useRef();
  const mapContainerRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<></>);

  let geolocation = null;
  if(isGeolocationEnabled){
    geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      // projection: map.getView().getProjection(),
    });
    geolocation.setTracking(true);
  }
  
  //const overlayRef = useRef();
  const popupRef = useRef(null);
  const [coordinate, setCoordinate] = useState(false);
  let overlay =  overlayInit( popupRef);
  const scaleLineControl = scaleLineInit();
  
  const DEFAULT_PROJECTION = "EPSG:4326";
  let combinedCenter = DEFAULT_CENTER; // default madrid
  let combinedExtent = false;
  const [defaultBaseMap, setDefaultBaseMap] = useState("arcgis");

  const baseLayer = baseLayerInit(defaultBaseMap);
  let map = null;
  const [mapState, setMapState] = useState(null);
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const SearchAddressDiv = document.createElement('div');
  SearchAddressDiv.className = "geoapify-autocomplete-input-box"
  const onPlaceSelect=(place)=>{
    console.log("onPlaceSelect : ", place);
    const lon = place?.properties?.lon;
    const lat = place?.properties?.lat;
    const street =  place?.properties?.formatted; 
    console.log("Coords", lat, lon, street);

    // map.getView().setCenter([lat,lon]);
    // map.getView().setZoom(17);
    
    map.getView().animate({
      center:[lon, lat],
      zoom:17,
      duration: 350 // Duración de la animación en milisegundos, ajusta según necesites
    });
    console.log("Enviando mapclick event barra busqueda", [lon, lat]);
    var evt = new CustomEvent("mapClick", {"detail":{"coordinates":[lon, lat], "location":street}});
    document.dispatchEvent(evt);
    addMarkerToMap(map, [lon, lat], pointMarker, assetsBasePath);
  }
  // function sendGeocoderRequest(value, geocoder) { // TODO
  //   console.log(value); //the search term
  //   return geocoder.sendGeocoderRequest(value);
      
  // }
  const root = createRoot(SearchAddressDiv);
  root.render(
  //ReactDOM.render(
  <GeoapifyContext  ref={geoapify_ref} apiKey={GEOAPIFY_KEY}>
      <GeoapifyGeocoderAutocomplete placeholder="Escriba una dirección aquí."
      //filterByCountryCode={["es"]}
      lang={"es"}
      //type={"street"}
      //debounceDelay={200}
      
      addDetails={false}
      placeSelect={onPlaceSelect}

      />
    </GeoapifyContext>);
  class LeyendControl extends Control {
    constructor(){
      let options =  {};
      let leyend = document.createElement('div');
      
      leyend.innerHTML = renderToString(layers_data[selectedLayer].legend());    
      let element = document.createElement('div');
      element.className = 'leyend-map ol-unselectable ol-control';
      element.appendChild(leyend);

      super({
          element: element,
          target: options.target
      });
    }
  }


useEffect(() => {
  
  drawSource.clear();
  if(map){
   // layerHasChanged = true;
   
    let center = map.getView().getCenter();
    let zoom = map.getView().getZoom();
    // oldZoom = zoom;
    // oldCenter = center;
    setLayerHasChanged(true);
    setOldCenter(center);
    setOldZoom(zoom);
    //console.log("Estableciendo antigua posición",oldZoom, oldCenter )

  }
  setDrawEnabled(isDrawEnabledOnStartUp);
  
}, [isDrawEnabledOnStartUp])


  class ToggleDrawGeometryControl extends Control {
    constructor() {
        let options =  {};
  
        let button = document.createElement('div');
       
        button.innerHTML = renderToString(<button style={{width:"20px", maxHeight:"20px"}} title="Dibujar geometría"><DrawIcon /></button>);    
  
        let element = document.createElement('div');
        if(isGeolocationEnabled) element.className = 'full-draw-control-next-row ol-unselectable ol-control';
        if(!isGeolocationEnabled) element.className = 'full-draw-control ol-unselectable ol-control';
        
        
        element.appendChild(button);
  
        super({
            element: element,
            target: options.target
        });
  
        button.addEventListener('click', this.handleToogleDrawState.bind(this), false);
    }
  
    handleToogleDrawState() {
      drawSource.clear();
      if(drawEnabled){
        var evt = new CustomEvent("drawGeometry",{ detail: { geometry: null }} );
        document.dispatchEvent(evt);
      }
      
      if(map){
       // layerHasChanged = true;
       
        let center = map.getView().getCenter();
        let zoom = map.getView().getZoom();
        // oldZoom = zoom;
        // oldCenter = center;
        setLayerHasChanged(true);
        setOldCenter(center);
        setOldZoom(zoom);
        //console.log("Estableciendo antigua posición",oldZoom, oldCenter )

      }
      setDrawEnabled(!drawEnabled);
    }
  }
  class CenterMapControl extends Control {
    constructor() {
        let options =  {};
  
        let button = document.createElement('div');
       
        button.innerHTML = renderToString(<button style={{width:"20px", maxHeight:"20px"}} title="Centrar mapa"><CenterFocusStrongIcon /></button>);    
  
        let element = document.createElement('div');
        element.className = 'full-center-map ol-unselectable ol-control';
        element.appendChild(button);
  
        super({
            element: element,
            target: options.target
        });
  
        button.addEventListener('click', this.handleCenterMap.bind(this), false);
    }
  
    handleCenterMap() {


      const view = map.getView();
      // Aquí es donde centras el mapa.
      if(combinedExtent != false){
        view.fit(combinedExtent, {
          padding: [150, 100, 150, 100],
          duration: 250,
          maxZoom:18        });
      }else{
        view.animate({
          center:combinedCenter,
          duration: 250 // Duración de la animación en milisegundos, ajusta según necesites
        });
      }
  
         
    }
  }
  class CenterOnCurrentLocationMapControl extends Control {
    constructor() {
        let options =  {};
  
        let button = document.createElement('div');
       
        button.innerHTML = renderToString(<button style={{width:"20px", maxHeight:"20px"}} title="Centrar mapa en la posición actual"><PlaceIcon /></button>);    
  
        let element = document.createElement('div');
        element.className = 'full-center-map-on-current-location ol-unselectable ol-control';
        element.appendChild(button);
  
        super({
            element: element,
            target: options.target
        });
  
        button.addEventListener('click', this.handleCenterMap.bind(this), false);
    }
  
    handleCenterMap() {


      const view = map.getView();
      // Aquí es donde centras el mapa.
      console.log("geoPositionPoint", geoPositionPoint)
      if(geoPositionPoint){
        view.animate({
          center:geoPositionPoint,
          zoom : 19,
          duration: 250 // Duración de la animación en milisegundos, ajusta según necesites
        })
      }else{
        const position = geolocation.getPosition()
        console.log("position", position)
        if(position){
          view.animate({
            center:position,
            zoom : 19,
            duration: 250 // Duración de la animación en milisegundos, ajusta según necesites
          })
        }else{
          Notiflix.Notify.warning("Error obteniendo la ubicación")

        }
       
      }
  
         
    }
  }
  class LayerSelectorControl extends Control {
    constructor() {
        let options =  {};
  
        let selector = document.createElement('select');
        selector.title = "Selector de mapa base";
        let selector_html = "<select>";//+"</select>"
        for(let layer_key in layers_data){
          selector_html += `<option ${selectedLayer === layer_key? 'selected="selected"':''} value="${layer_key}">${layers_data[layer_key].label} </option>`
        }
        selector_html += "</select>";
        selector.innerHTML = selector_html;
        selector.className='layer-changer-selector'
        let element = document.createElement('div');
        if(!isAdressSearchBarEnabled) element.className =  'layer-changer-map ol-unselectable ol-control';
        if(isAdressSearchBarEnabled) element.className =  'layer-changer-map-2row ol-unselectable ol-control';
        element.appendChild(selector);
        super({
            element: element,
            target: options.target
        });
  
        selector.addEventListener('change', this.handleChangeLayer.bind(this), false);
    }
  
    handleChangeLayer(e) {
        const selectedValue = e.target.value;
        
        if(map){
         // layerHasChanged = true;
          let center = map.getView().getCenter();
          let zoom = map.getView().getZoom();
          // oldZoom = zoom;
          // oldCenter = center;
          setLayerHasChanged(true);
          setOldCenter(center);
          setOldZoom(zoom);
          //console.log("Estableciendo antigua posición",oldZoom, oldCenter )

        }
        setSelectedLayer(selectedValue);
        //initProjections();
      }
  }
  class BaseMapSelectorControl extends Control {
    constructor() {
      let options =  {};

      let div = document.createElement('div');
      
       
      
      const img_folder = assetsBasePath ? assetsBasePath : process.env.PUBLIC_URL+"/images/";
      const img_satellite = 'map_satellite.jpg'; //arcgis
      const img_street = 'map_street.jpg'; //light_all
      const satellite_value = "arcgis";
      const street_value = "osm";
      //console.log("ChangeBasemap defaultBaseMap", defaultBaseMap, "equal to ", defaultBaseMap ==satellite_value)
      div.innerHTML = defaultBaseMap == satellite_value ? renderToString(<img  value={satellite_value} src={img_folder+img_street} />):renderToString(<img value={street_value} src={img_folder+img_satellite} />);
      div.value = defaultBaseMap == satellite_value ? satellite_value : street_value;
      //div.setAttribute("value",street_value );
      div.title = "Vista callejero";

      // if(defaultBaseMap == satellite_value){
      //   //div.innerHTML ='<img src="'+img_folder+img_street+'" />';

      //   div.setAttribute("value",satellite_value );

      // }else{
      //   //div.innerHTML ='<img src="'+img_folder+img_satellite+'" />';
      //   div.innerHTML =renderToString(<img src={img_folder+img_satellite} />);

      //   div.setAttribute("value",street_value );
      // }
      
      div.className='basemap-changer-selector-image'
      let element = document.createElement('div');
      element.className =  'full-basemap-changer-map ol-unselectable ol-control';
      element.appendChild(div);
      super({
          element: element,
          target: options.target
      });
  
      div.addEventListener('click', this.handleChangeBasemap.bind(this), false);
    }
  
    handleChangeBasemap(e, data) {
        const img_folder =  assetsBasePath ? assetsBasePath : process.env.PUBLIC_URL+"/images/";
        const img_satellite = 'map_satellite.jpg'; //arcgis
        const img_street = 'map_street.jpg'; //light_all
        const satellite_value = "arcgis";
        const street_value = "osm";
       // console.log("handleChangeBasemap Event", e, data)
        const selectedValue = e.target.getAttribute("value");
        let selectedMap = null;
        //console.log("handleChangeBasemap defaultBaseMap", defaultBaseMap,selectedValue,  selectedValue == satellite_value)
        if(selectedValue == satellite_value){
          e.target.setAttribute( "src" ,img_folder+img_satellite);
          //e.target.innerHTML ='<img src="'+img_folder+img_satellite+'" />';

          //console.log("handleChangeBasemap cambiando a", street_value)
          setDefaultBaseMap(street_value);
          selectedMap = street_value;

          e.target.setAttribute("value",street_value );
          e.target.setAttribute( "title" ,"Vista satélite");

        }else{
         // e.target.innerHTML =renderToString(<img src={img_folder+img_street} />);
         e.target.setAttribute( "src" ,img_folder+img_street);
         e.target.setAttribute( "title" ,"Vista callejero");

          //e.target.innerHTML ='<img src="'+img_folder+img_street+'" />';
          
          //console.log("handleChangeBasemap cambiando a", satellite_value)
          setDefaultBaseMap(satellite_value);
          selectedMap = satellite_value;
          e.target.setAttribute("value",satellite_value );

        }
        
        let sourceBaseMap = getSourceBaseMapFromName(selectedMap);
        map.getLayers().setAt(0, new TileLayer({
          source:sourceBaseMap,
        }));
        initProjections();
      }
  }
  class FullScreenButtonControl extends Control {
    constructor() {
      let options =  {};
      let button = document.createElement('div');
      let enterFullScreenButton =  renderToString(<button  style={{maxWidth:"20px", maxHeight:"20px"}} title="Activar pantalla completa"><FullscreenSharpIcon/></button>);    
      let exitFullScreenButton =  renderToString(<button  style={{maxWidth:"20px", maxHeight:"20px"}} title="Desactivar pantalla completa"><FullscreenExitIcon/></button>);    
      button.innerHTML = isFullScreen ? exitFullScreenButton : enterFullScreenButton;
      let element = document.createElement('div');
      element.className = 'full-full-screen-map-button ol-unselectable ol-control';
      element.appendChild(button);

      super({
          element: element,
          target: options.target
      });

      button.addEventListener('click', this.toggleFullScreen.bind(this), false);
    }
  
    enableFullscreen = () => {
      const mapElement = mapContainerRef.current;
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      } else if (mapElement.mozRequestFullScreen) {
        mapElement.mozRequestFullScreen();
      } else if (mapElement.webkitRequestFullscreen) {
        mapElement.webkitRequestFullscreen();
      } else if (mapElement.msRequestFullscreen) {
        mapElement.msRequestFullscreen();
      }
    };
    toggleFullScreen = () => {
      const mapElement = mapContainerRef.current;
     // console.log("Toggle full screen", isFullScreen);
      try{
        if (!isFullScreen) {
          setIsFullScreen(true);
          if (mapElement.requestFullscreen) {
            mapElement.requestFullscreen();
          } else if (mapElement.mozRequestFullScreen) {
            mapElement.mozRequestFullScreen();
          } else if (mapElement.webkitRequestFullscreen) {
            mapElement.webkitRequestFullscreen();
          } else if (mapElement.msRequestFullscreen) {
            mapElement.msRequestFullscreen();
          }
          
        } else {
          setIsFullScreen(false);
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      
        
      }catch(error){
        console.error("Error en la pantalla completa", error);
      }
    };
  }
  const checkFullScreen = () => {
    const isCurrentlyFullScreen = document.fullscreenElement !== null;
    if (isFullScreen !== isCurrentlyFullScreen) {
      setIsFullScreen(isCurrentlyFullScreen);
    }
  };
  useEffect(() => {
    document.addEventListener("fullscreenchange", checkFullScreen);

    return () => {
      document.removeEventListener("fullscreenchange", checkFullScreen);
    };
  }, [isFullScreen]);



    
  useEffect(() => {
    // Registro de la proyección EPSG:25830 (Si no está ya definido en OpenLayers)
   // console.log("Inicializando mapa y creando proyecciones...");
    initProjections();
    
  }, []);

  useEffect(() => {
   // console.log("Actualizando geometría...", geojsons.substring(0, 25));
    // Creación de la capa de mapa base usando OSM (Open Street Map)
    // Creación de la capa vectorial usando los datos GeoJSON, si se proporcionan
    let polygonVectorLayers = false;
    let pointVectorLayers = false;
    combinedCenter = DEFAULT_CENTER;
    combinedExtent = false;
    if (
      geojsons == null ||
      geojsons == "" ||
      geojsons == undefined ||
      geojsons == false ||
      geojsons == "[]" 
    ) {
      geojsons = "[]";
    } else {
      
      let allPolygons;
      let allPoints;
      let vectorSources;
      let markerSources;
      
      // Crear una capa vectorial para cada VectorSource
      if(selectedLayer == "transparent"){
        ({allPolygons, allPoints, vectorSources, markerSources} = readGeoJsons("[]",DEFAULT_PROJECTION )); 
        combinedCenter = oldCenter;
      }else{
        ({allPolygons, allPoints, vectorSources, markerSources} = readGeoJsons(geojsons,DEFAULT_PROJECTION )); 
        polygonVectorLayers = getVectorLayerFromPointVectorSourcesV2( polygonVectorLayers, vectorSources, allPolygons, strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, mapState, getBgColorFromFeature);
        pointVectorLayers = getVectorLayerFromPointVectorSourcesV2( pointVectorLayers,     markerSources, allPoints,   strokeColor, strokeWidth, fillColor, fillPointsOpacity,   mapState, getBgColorFromFeature);
        ({ combinedExtent, combinedCenter } = calculeCenterOfFeatures(combinedExtent,allPolygons, allPoints));
      }
      
      // Calcula el centro combinado
     
    }

    // Crear el mapa con todas las capas vectoriales

    //console.log("combinedCenter", combinedCenter);
    if (mapRef.current) {
      mapRef.current.innerHTML = "";
    }
    overlay = overlayInit(popupRef);
    const tooltip = tooltipInit(tooltipRef);
    if(isDrawEnabled){
      drawSource = new VectorSource({wrapX: false});
      
    }
    map = mapInit( 
      mapRef, 
      tooltip, 
      overlay, 
      baseLayer, 
      polygonVectorLayers, 
      pointVectorLayers, 
      combinedCenter, 
      DEFAULT_PROJECTION, 
      scaleLineControl, 
      drawEnabled, 
      drawSource, 
      oldZoom, 
      oldCenter, 
      isGeolocationEnabled,
       );
    setMapState(map)
    if(isGeolocationEnabled){
      // if(!geolocation.getProjection()){
      //   geolocation.setProjection(map.getView().getProjection());
      // }
      addGeolocation(map,geolocation,  geoPositionPoint, setGeoPositionPoint, geoAccuraccyGeometry, setGeoAccuraccyGeometry)
    }
    
    if(selectedLayer != "transparent" && !drawEnabled){
      map.on('moveend', () => updateLayerStyle(map, polygonVectorLayers, strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, getBgColorFromFeature));
      map.on('moveend', () => updateLayerStyle(map, pointVectorLayers, strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, getBgColorFromFeature));
    }
    
    var centerControl = new CenterMapControl();
    map.addControl(centerControl);
    const mapSelector = new BaseMapSelectorControl();
    map.addControl(mapSelector);
    const fullScreenButton = new FullScreenButtonControl();
    map.addControl(fullScreenButton);
    map.addControl(new LeyendControl());
    if(isGeolocationEnabled){
      const centerLocationControl = new CenterOnCurrentLocationMapControl();
      map.addControl(centerLocationControl);

    }
    if(isAdressSearchBarEnabled) {
      const SearchAddressControl = new Control({
        element: SearchAddressDiv,
      });
      map.addControl(SearchAddressControl)
    };
    if(isDrawEnabled) map.addControl(new ToggleDrawGeometryControl());
    
    
    if(Object.keys(layers_data).length > 1){
      const layerSelector = new LayerSelectorControl();
      map.addControl(layerSelector);
    }
    // let geopositionCoords = null;
    // if(isGeolocationEnabled){
    //   console.log("Obteniendo centro de la localización")
    //   let geopositionCoords = geolocation.getPosition()
    //   console.log("geopositionCoords ", geopositionCoords)
    //   map.getView().setCenter(geopositionCoords)
    //   map.getView().setZoom(17)
    // }
    if (((polygonVectorLayers || pointVectorLayers) && combinedExtent) && selectedLayer != "transparent") {
      if(!layerHasChanged){
        map.getView().fit(combinedExtent, {
          padding: [150, 100, 150, 100],
        });
        if(map.getView().getZoom() > 20){
          map.getView().setZoom(17)
        }
      }
      if(!drawEnabled ){
        map.on("pointermove", function (evt) {
          handleMapPointerMove(
            map,
            evt,
            layers_data[selectedLayer],
            tooltip,
            tooltipRef,
            strokeColor, strokeHoverColor, strokeWidth,   
            fillColor, fillPolygonsOpacity, fillPointsOpacity,
            getBgColorFromFeature,
            isGetCoordinatesOnClickEnabled
          );
        });
      }
      
    } else {
      if(!layerHasChanged && selectedLayer != "transparent" ){
        map.getView().setZoom(5);
      }
     
      
    }
    map.on("singleclick", async function (evt) {
      handleMapClick(map, evt, overlay, setModalBody, setIsModalOpen, layers_data[selectedLayer], mustShowOnClickFeature, isGetCoordinatesOnClickEnabled, pointMarker, assetsBasePath, drawEnabled);
    });
    //console.log("Comprobando antigua posición", layerHasChanged, oldCenter, oldZoom)
    if(oldCenter && oldZoom){
      //console.log("Recuperando antigua posición", oldCenter, oldZoom);
      map.getView().setCenter(oldCenter);
      map.getView().setZoom(oldZoom);
      // map.getView().animate({
      //   center: oldCenter,
      //   zoom: oldZoom,
      //   duration: 1050 // Duración de la animación en milisegundos, ajusta según necesites
      // });
    }
    setLayerHasChanged(false);
    if(drawEnabled){
      
        draw = new Draw({
        source: drawSource,
        type: drawType,
     //   style:getPolygonWithPointsStyle(),
//         style:{
// "circle-radius":5,
// "circle-fill-color":"orange",
// "circle-stroke-color":"blue",
// "fill-color":"blue",
// "icon-color":"black",
// "stroke-line-join":"bevel"
//         }
      });
      draw.on('drawend', function(evt){
        console.log("Acabando dibujo")
        // var DrawnFeature = evt.feature;
        // var DrawnCoords = evt.feature.getGeometry().getCoordinates();
        var writer = new GeoJSON();
        const geojson = writer.writeFeatureObject(evt.feature instanceof RenderFeature ? toFeature(evt.feature) : evt.feature);
        //console.log("Drawn data drawend", geojson);

        mustClean = true;
        var evt = new CustomEvent("drawGeometry",{ detail: { geometry: geojson } });
        document.dispatchEvent(evt);
      });
      draw.on('drawstart', function(evt){
        //console.log("Drawn data drawstart",mustClean);
        if(mustClean){
          console.log("Limpiando dibujo")
          drawSource.clear();
          mustClean=false;
        }
        var writer = new GeoJSON();
         let ft = evt.feature instanceof RenderFeature ? toFeature(evt.feature) : evt.feature;
         console.log("draw point", ft, ft.getGeometry().getCoordinates()[0][0])
         let new_ft = new Point(ft.getGeometry().getCoordinates()[0][0])
        //const geojson = writer.writeFeatureObject(new_ft);
        console.log("DrawStart feature", new_ft);
        //draw.setStyle(getPolygonWithPointsStyle(true))
        
      })
      draw.on("change", function(evt){
      //  draw.setStyle(getPolygonWithPointsStyle());
      })
      
      map.addInteraction(draw);
    }

    return () => map?.setTarget(undefined);
  }, [geojsons, isFullScreen, selectedLayer, drawEnabled, oldCenter, oldZoom, isDrawEnabled, mustShowOnClickFeature]);



  return (
    <div ref={mapContainerRef} style={style}>
      <div
        ref={mapRef}
        style={{ width: "100%", height: "100%", zIndex: "10" }}
      />
      
      <div ref={tooltipRef} className="tooltip" />
      {/* <div ref={popupRef} id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div ref={overlayRef}></div>
          
      </div> */}
      <GenericInfoMapModal
        buttonAction={() => {}}
        ModalButton={<></>}
        InitialAction={() => {}}
        Title={"Información"}
        BodyComponent={modalBody}
        ConfirmText={"OK"}
        CancelText={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fullScreenContainerRef={mapContainerRef}
        isFullScreen={isFullScreen}
      />
    </div>
  );
};


export default OpenLayersMap;



