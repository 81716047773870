import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import {
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import "./GenericTextModalConfirmationWithButton.css";
import { Loading } from "notiflix";

const GenericTextModalConfirmationWithButton = ({
  buttonAction,
  ModalButton,
  InitialAction = () => {},
  Title,
  BodyComponent,
  ConfirmText = "Confirm",
  CancelText = "Cancel",
  isModalOpenExternal = false,
  endAction = ()=>{},
  _width="auto",
  _maxWidth="100%"

}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(()=>{
      setIsModalOpen(isModalOpenExternal)
  }, [isModalOpenExternal])
  return (
    <>
      {/* <Button onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }} className='management-control-button'><AddCircleRounded style={buttonStyle}/>{buttonText}</Button> */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsModalOpen(true);
          InitialAction();
        }}
      >
        {ModalButton}
      </div>
      <Modal
        
        width={_width}
        style={{maxWidth:_maxWidth}}
        open={isModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          setIsModalOpen(false);
          endAction()
        }}
        footer={[
          <div style={{ display: "flex", placeContent: "space-around" }}>
            {/* <div style={{display:"flex", placeContent:"space-arround"}}>  */}
            {CancelText && (
              <Button
                class="new-user-modal-cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen(false);
                  endAction()
                }}
              >
                {CancelText}
              </Button>
            )}
            <Button
              class="new-user-modal-saveButton"
              onClick={(e) => {
                e.stopPropagation();
                buttonAction();
                setIsModalOpen(false);
                endAction()
              }}

            >
              {ConfirmText}
            </Button>
            {/* </div> */}
          </div>,
        ]}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p>
            <b style={{ color: "var(--color-fourth)", fontSize: "18px" }}>
              {Title}
            </b>
          </p>
          <hr class="solid" color="var(--color-fourth)" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            {BodyComponent}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenericTextModalConfirmationWithButton;
