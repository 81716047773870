import React, { useEffect, useState } from "react";
import ScrollToTop from "./ScrollToTop";
import "./Main.css";
import Header from "../../../HeaderV2";
import { Paper } from "@mui/material";
import en from "./i18n/en";
import es from "./i18n/es";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import CatastroForm from "../../forms/CatastroForm/CatastroForm";
import Footer from "../../../Footer";
import { FaAngleUp } from "react-icons/fa";

i18next.addResourceBundle("en", "MAIN", en);
i18next.addResourceBundle("es", "MAIN", es);

const Main = () => {
  const { t } = useTranslation("MAIN");
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
     
            <CatastroForm></CatastroForm>
        
  );
};

export default Main;
