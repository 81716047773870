import React, { useEffect, useState, useContext } from "react";

import "./App.scss";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import PrivateRoute from "./commons/PrivateRoute";
import jwt from "jwt-decode"; // import dependency
import AppContext from "./AppContext";
import Main from "./components/screens/Main/Main";
import WebFont from "webfontloader";
import Login from "./components/screens/Login/Login";
import ExampleMap from "./components/screens/Example/ExampleMap";
import PublicMap from "./components/screens/PublicMap/PublicMap";
function App() {
  const { token } = useContext(AppContext);
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["IBM Plex Mono"],
      },
    });
  }, []);
  React.useEffect(() => {}, [token]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {token ? (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/main" element={<Main />} index />
              <Route path="*" element={<Login />} />
            </>
          ) : (
            <>
               {/* <Route path="/main" element={<Main />} index /> */}
              <Route path="*" element={<Login />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
